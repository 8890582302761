import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import api from "../utils/api";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from "../components/button";
import { toLocalTime } from "../utils/timestamps";
import { Link } from "react-router-dom";
import { mobile, smallDesktop, tablet } from "../styles/media";
import { Input } from "../components/input";
import { useLocation } from "react-router";
import { colorGrayLight } from "../utils/constants";
import { Calendar } from "../icons/calendar";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Archive = () => {
  const [hyperlinks, sethyperlinks] = useState({});
  const [items, setItems] = useState([]);
  const [titleSearch, setTitleSearch] = useState("");
  const [userSearch, setUserSearch] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const perPage = 20;
  const defaultUrl = `/api/canvas?active=false&per_page=${perPage}`;
  const location = useLocation();
  const { t } = useTranslation();

  const load = async () => {
    const search = location.search;
    const params = new URLSearchParams(search);
    parseUrlParams(params);
    searchCanvases();
    let clearAll = document.getElementById("clear");
    clearAll?.addEventListener("click", clearInputs);
    let applySearch = document.getElementById("search");
    applySearch.addEventListener("click", searchCanvases);
    document.addEventListener("keypress", enterSearch);
  };

  const enterSearch = (key) => {
    if (key.code === "Enter") {
      searchCanvases();
    }
  };

  const parseUrlParams = (params) => {
    let searchType, searchTitle, startTime, endTime, users;
    searchTitle = params.get("title");
    users = params.get("users");
    startTime = params.get("start_time");
    endTime = params.get("end_time");
    if (searchTitle) {
      document.getElementById("titleSearchInput").value = searchTitle;
    }
    if (users) {
      document.getElementById("userSearch").value = users;
    }
    if (startTime) {
      document.getElementById("startDate").value = startTime;
    }
    if (endTime) {
      document.getElementById("endDate").value = endTime;
    }
    return [searchType, searchTitle, startTime, endTime];
  };

  const updateApiData = useCallback(
    (json) => {
      sethyperlinks(json._links);
      setItems(items.concat(json.items));
      if (json.items.length < perPage) {
        setHasMore(false);
      }
    },
    [items]
  );

  const searchCanvases = useCallback(async () => {
    let searchValue = document.getElementById("titleSearchInput").value;

    if (searchValue) {
      encodeURIComponent(JSON.stringify(searchValue.toLowerCase().split(" ")));
    }
    let user = document.getElementById("userSearch").value;
    const startDate = document.getElementById("startDate").value;
    const endDate = document.getElementById("endDate").value;
    let url = "/api/canvas?";
    if (searchValue) {
      url += `title=${searchValue}`;
    }
    if (user) {
      if (url.endsWith("?")) {
        url += `users=${user}`;
      } else {
        url += `&users=${user}`;
      }
    }
    if (startDate) {
      if (url.endsWith("?")) {
        url += `start_time=${startDate}`;
      } else {
        url += `&start_time=${startDate}`;
      }
    }
    if (endDate) {
      if (url.endsWith("?")) {
        url += `end_time=${endDate}`;
      } else {
        url += `&end_time=${endDate}`;
      }
    }
    if (url === "/api/canvas?") {
      url = defaultUrl;
    }
    const [err, res] = await api(url);
    if (err !== null) {
      toast.error(`${t("archive_page.search_error")}`);
      return;
    }
    const json = await res.json();
    window.history.pushState(
      "",
      "Archive",
      window.location.href.split("?")[0] + "?" + url.split("?")[1]
    );
    updateApiData(json);
  }, [updateApiData, defaultUrl, t]);

  const titleSearchChange = useCallback(
    (e) => {
      setTitleSearch(e.target.value);
      if (e.target.value !== "") {
        document.getElementById("userSearch").disabled = true;
        document.getElementById("userSearch").placeholder = t(
          "archive_page.search_disabled_message"
        );
      } else {
        document.getElementById("userSearch").disabled = false;
        document.getElementById("userSearch").placeholder = t(
          "archive_page.user_search_placeholder"
        );
      }
    },
    [t]
  );

  const userSearchChange = (e) => {
    setUserSearch(e.target.value);
    if (e.target.value !== "") {
      document.getElementById("titleSearchInput").disabled = true;
      document.getElementById("titleSearchInput").placeholder = t(
        "archive_page.search_disabled_message"
      );
    } else {
      document.getElementById("titleSearchInput").disabled = false;
      document.getElementById("titleSearchInput").placeholder = t(
        "archive_page.title_search_placeholder"
      );
    }
  };

  const clearInputs = useCallback(() => {
    setUserSearch("");
    setTitleSearch("");
    document.getElementById("titleSearchInput").value = "";
    document.getElementById("userSearch").value = "";
    document.getElementById("titleSearchInput").disabled = false;
    document.getElementById("userSearch").disabled = false;
    document.getElementById("titleSearchInput").placeholder = t(
      "archive_page.title_search_placeholder"
    );
    document.getElementById("userSearch").placeholder = t(
      "archive_page.user_search_placeholder"
    );
    document.getElementById("startDate").value = "";
    document.getElementById("endDate").value = "";
    searchCanvases();
  }, [searchCanvases, t]);

  const getMoreCanvases = useCallback(async () => {
    const [err, res] = await api(hyperlinks.next);
    if (err !== null) {
      toast.error(`${t("archive_page.loading_canvases_error")}`);
    }
    const json = await res.json();
    updateApiData(json);
  }, [hyperlinks, updateApiData, t]);

  useEffect(() => {
    load();
    return () => {
      document.removeEventListener("keypress", enterSearch);
    };
  }, []);

  return (
    <Container>
      <InnerContainer>
        <Title>{t("archive_page.page_title")}</Title>
        <SearchContainer>
          <SearchTitle>{t("archive_page.title_search_label")}</SearchTitle>
          <SearchInput
            id="titleSearchInput"
            placeholder={t("archive_page.title_search_placeholder")}
            value={titleSearch}
            onChange={titleSearchChange}
          ></SearchInput>
          <SearchTitle>{t("archive_page.user_search_label")}</SearchTitle>
          <SearchInput
            id="userSearch"
            placeholder={t("archive_page.user_search_placeholder")}
            value={userSearch}
            onChange={userSearchChange}
          ></SearchInput>
          <SearchTitle>{t("archive_page.date_range_label")}</SearchTitle>
          <Row>
            <Group>
              <DateInput type="date" id="startDate"></DateInput>
              <DateInput type="date" id="endDate"></DateInput>
            </Group>
            <Group>
              <ClearButton
                id="clear"
                text="Clear All"
                bgColor={{ normal: colorGrayLight, hover: "#707070" }}
                color={{ normal: "white", hover: "white" }}
              ></ClearButton>
              <SearchButton id="search" text="Apply"></SearchButton>
            </Group>
          </Row>
        </SearchContainer>
        <Gallery>
          <InfiniteScroll
            dataLength={items.length}
            next={getMoreCanvases}
            hasMore={hasMore}
            loader={<h4>{t("archive_page.loading")}...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                {t("archive_page.no_more_canvases")}
              </p>
            }
          >
            {items.map((item, idx) => {
              return (
                !item.active && (
                  <Thumb key={item.id}>
                    <Description>
                      <ThumbTitle>
                        {item.title === ""
                          ? t("archive_page.untitled")
                          : item.title}
                      </ThumbTitle>
                    </Description>
                    <Link
                      to={{
                        pathname: `/admin/archive/${item.id}`,
                        canvas: { ...item },
                      }}
                    >
                      <img
                        alt={`Canvas ${item.id}`}
                        id={item.id}
                        src={item.image}
                      />
                    </Link>
                    <Timestamp id="timestamp">
                      <Calendar size="0.75rem"></Calendar>
                      {toLocalTime(item.timestamp)}
                    </Timestamp>
                  </Thumb>
                )
              );
            })}
          </InfiniteScroll>
          {hasMore && (
            <Button callback={getMoreCanvases}>
              {t("archive_page.load_more_canvases")}
            </Button>
          )}
        </Gallery>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  @media ${mobile} {
    padding-top: 4rem;
  }
`;

const InnerContainer = styled.div`
  max-width: 1586px;
  margin: 2rem auto 0;
  padding: 0 4.5rem;
  @media ${smallDesktop} {
    padding: 0 2.5rem;
  }

  @media ${tablet} {
    padding: 0 1.5rem;
  }
`;

const Title = styled.h2`
  clear: both;
  margin: 0 4rem;
  padding: 1rem 0 1.5rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-family: NetflixSansBold;
`;

const SearchContainer = styled.div`
  padding: 1.25rem 4rem 2.75rem;
  margin-bottom: 2rem;
  max-width: 973px;
  background-color: white;
  border-radius: 0.413rem;
  box-shadow: 0px 3px 6px #00000029;
  box-sizing: border-box;
  input:disabled {
    background-color: ${colorGrayLight};
  }
`;

const SearchTitle = styled(Title)`
  margin: 0;
  padding: 1rem 0;
`;

const SearchInput = styled(Input)`
  float: left;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  @media ${tablet} {
    display: block;
  }
`;

const DateInput = styled(Input)`
  float: left;
  max-width: 12rem;
  margin-right: 2rem;
  @media ${tablet} {
    width: 50%;
    max-width: unset;
    &:nth-of-type(2) {
      margin-right: 0;
    }
  }
`;

const SearchButton = styled(Button)`
  border-radius: 0;
  box-shadow: unset;
  width: 12rem;
  margin-right: 1rem;
  height: 2rem;
  &:first-of-type {
    margin-left: 1rem;
  }

  @media ${tablet} {
    margin-left: 0 !important;
  }
`;

const ClearButton = styled(SearchButton)`
  border: none;
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: auto;
  clear: both;
`;

const Description = styled.div`
  margin: 0;
  padding: 0;
`;

const Thumb = styled.div`
  max-width: 100vw;
  padding: 0.25rem 0rem 2rem;
  position: relative;

  img {
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
  }
`;

const ThumbTitle = styled(Title)`
  padding-top: 1rem;
`;

const Timestamp = styled.div`
  float: right;
  font-family: "Nunito Sans", sans-serif;
  margin: 0rem 0;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  bottom: 0rem;
  right: 0rem;
  position: absolute;
  svg {
    margin-right: 0.2rem;
  }

  @media ${mobile} {
    font-size: 0.75rem;
    margin: 2rem 0;
    &.active {
      margin: 0.2rem 4rem !important;
      right: 2rem;
    }
  }
`;

const Group = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  @media ${tablet} {
    &:nth-of-type(2) {
      margin-top: 1.5rem;
    }
  }
`;

export { Archive };

const api = async (path, options) => {
  try {
    const response = await fetch(`${path}`, options);
    if (response.status >= 400) {
      const msg = await response.text();
      throw msg;
    }
    return [null, response];
  } catch (e) {
    return [e, null];
  }
};

export default api;

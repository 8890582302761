import React from "react";
import styled from "styled-components";
import { Zoom } from "../icons/zoom";
import { mobile } from "../styles/media";

const ToolDoubleButton = ({
  id,
  size = 62,
  textLeft = null,
  leftId = null,
  rightId = null,
  textRight = null,
  callbackLeft = null,
  callbackRight = null,
  ...styledProps
}) => {
  return (
    <DoubleButton id={id} size={size} {...styledProps}>
      <ButtonHalf
        borderRadius={"7px 0 0 7px"}
        data-side={"left"}
        border={"none"}
        margin={"0 1px 0 0"}
        className="left-button"
        size={size}
        onClick={callbackLeft ? callbackLeft : () => {}}
        id={leftId}
      >
        {textLeft !== null ? (
          textLeft
        ) : (
          <Zoom
            type="zoom-in"
            style={{ userSelect: "none", pointerEvents: "none" }}
          ></Zoom>
        )}
      </ButtonHalf>
      <ButtonHalf
        borderRadius={"0 7px 7px 0"}
        data-side={"right"}
        border={"none"}
        margin={"0"}
        className="right-button"
        size={size}
        onClick={callbackRight ? callbackRight : () => {}}
        id={rightId}
      >
        {textRight !== null ? (
          textRight
        ) : (
          <Zoom
            type="zoom-out"
            style={{ userSelect: "none", pointerEvents: "none" }}
          ></Zoom>
        )}
      </ButtonHalf>
    </DoubleButton>
  );
};

const Button = styled.button`
  background-color: white;
  border: none;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  padding: 0;
  font-family: NetflixSansLight;
  font-size: 1.25rem;
  color: #7b7676;

  :hover {
    background-color: #dedede;
    color: #7b7676;
    cursor: pointer;
    svg {
      /*filter: invert(1) contrast(1.5) brightness(1.3);*/
    }
  }

  /*:active {
    background-color: #7b7676;
    color: white;
    svg {
      fill: white;
    }
  }*/

  &:first-of-type {
    ::after {
      content: "";
      width: 1px;
      background-color: #dedede;
      height: 53px;
      position: absolute;
      top: 0.25rem;
      right: 62px;
    }
  }
  &.selected {
    background-color: #7b7676;
    color: white;
  }

  /*@media ${mobile} {
    width: 32px;
    height: 32px;

    svg {
      transform: scale(${32 / 46});
    }
  }*/
`;

const DoubleButton = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  background-color: white;
  border-radius: 7px;
  margin: 0;
  padding: 0;
  height: ${(props) => props.size}px;
  position: relative;

  /*  @media ${mobile} {
    height: 32px;
  }*/
`;

const ButtonHalf = styled(Button)`
  margin: 0;
  border-radius: ${(props) => props.borderRadius};
  margin: ${(props) => props.margin};

  /*:before {
    content: "";
    position: absolute;
    right: 49%;
    top: 20%;
    height: 60%;
    width: 50%;
    border-right: ${(props) => props.border};
    pointer-events: none;
  }*/
`;

export { ToolDoubleButton };

import api from "./api";
import { toast } from "react-toastify";

const archiveCanvas = async (userToken, id, successMsg) => {
  let toastId = toast("Archiving In Progress, please stand by...", {
    autoClose: false,
  });
  const [errors, response] = await api(`/api/canvas/${id}/archive`, {
    method: "POST",
    headers: {
      "X-Access-Token": userToken,
    },
  });
  if (errors !== null) {
    console.log("Error Archiving Canvas");
    toast.update(toastId, {
      type: toast.TYPE.ERROR,
      render: "Error while archiving",
    });
  } else if (response.status === 200) {
    toast.update(toastId, {
      type: toast.TYPE.SUCCESS,
      render: successMsg,
      autoClose: true,
    });
  }
};

export { archiveCanvas };

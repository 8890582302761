import React from "react";
import styled from "styled-components";
import { Pencil } from "../icons/pencil";
import { Pan } from "../icons/pan";
import { mobile } from "../styles/media";

const ToolButton = ({
  type,
  isSelected = false,
  callback,
  text,
  size = 62,
  id = null,
}) => {
  const icons = {
    draw: Pencil,
    pan: Pan,
    text: null,
  };

  let Component = icons[type];

  const appearSelected = isSelected;

  return (
    <Button
      selected={appearSelected}
      onClick={callback}
      text={text}
      size={size}
      id={id}
    >
      {Component && <Component></Component>}
      {text && text}
    </Button>
  );
};

const Button = styled.button`
  background-color: ${(props) => (props.selected ? "#7B7676" : "white")};
  border: none;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 7px;
  box-shadow: 0px 3px 6px #00000029;
  margin: 0 0.5rem;
  font-size: 0.7rem;
  padding: 0;

  :hover {
    background-color: #dedede;
    cursor: pointer;
    color: #7b7676;
    svg {
      color: #7b7676;
      fill: #7b7676 !important;
    }
  }
  svg {
    fill: ${(props) => (props.selected ? "white" : "#7b7676")};
  }

  /*@media ${mobile} {
    width: 32px !important;
    height: 32px !important;
    margin: 0 0.25rem;
    svg {
      transform: scale(${32 / 46});
    }
  }*/
`;

export { ToolButton };

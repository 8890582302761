import { useContext } from "react";
import firebase from "firebase/app";
import { FirebaseAuth } from "react-firebaseui";
import styled from "styled-components";
import { AuthContext } from "../context/auth";
import logo from "../imgs/Netflix_Logo_PMS.png";
import logoSymbol from "../imgs/Symbol_NoPad.png";
import { desktop, mobile, smallDesktop } from "../styles/media";
import { useTranslation } from "react-i18next";

const SignIn = () => {
  const { failure } = useContext(AuthContext);
  const { t } = useTranslation();

  const uiConfig = {
    signInFlow: firebase.auth().isSignInWithEmailLink(window.location.href)
      ? "redirect"
      : "popup",
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        buttonColor: "#222222",
        labelColor: "#CCCCCC",
      },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        buttonColor: "#222222",
        labelColor: "#CCCCCC",
        iconUrl: "",
        emailLinkSignIn: () => {
          return {
            url: window.location,
            handleCodeInApp: true,
          };
        },
      },
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  };

  if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
    firebase
      .auth()
      .signInWithEmailLink(window.location.href)
      .then((result) => {
        console.log("sign in result", result);
        //window.localStorage.removeItem("emailForSignIn");
      })
      .catch((error) => {
        console.log("error sign in");
      });
  }

  return (
    <Container id="SignIn">
      <Logo src={logo}></Logo>
      <Login>
        <h1>{t("sign_in.login_label")}</h1>
        {!failure && <h2>{t("sign_in.cta")}</h2>}
        {failure && (
          <div style={{ "z-index": 1000 }}>
            {t("sign_in.error")}{" "}
            <Clickable onClick={() => window.location.reload()}>
              {t("sign_in.try_again")}
            </Clickable>
          </div>
        )}
        <FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </Login>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  background-image: url(${logoSymbol});

  @media ${desktop} {
    background-size: contain;
    background-position: 1188px 0px;
  }
  background-repeat: no-repeat;
  background-position: right top;
  background-size: auto 100%;
  h1 {
    color: #f5f5f5;
    font-family: NetflixSansBold;
    font-size: 2.25rem;
  }
  .firebaseui-title {
    font-size: 1.5rem !important;
  }
  @media ${smallDesktop} {
    #firebaseui_container,
    .firebaseui-container,
    .firebaseui-card-content {
      /*width: 50vw !important;
      max-width: 600px !important;*/
    }
  }

  @media ${mobile} {
    #firebaseui_container,
    .firebaseui-container,
    .firebaseui-idp-button,
    .firebaseui-card-content {
      width: 100% !important;
      margin: 0 auto !important;
    }
    h1,
    h2 {
      width: 100%;
      max-width: 400px;
      margin: auto;
    }
  }
  h2 {
    font-family: NetflixSansLight;
    font-size: 1.2rem;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 1.16px;
    margin-bottom: 3rem;
  }
`;

const Logo = styled.img`
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 10rem;
`;

const Login = styled.div`
  width: 508px;
  margin: 0 auto;
  padding: 0;
  color: #f5f5f5;
  @media ${mobile} {
    width: 90%;
  }
`;

const Clickable = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export default SignIn;

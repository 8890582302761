import { fullWidth, height } from "./constants";

const updateViewport = (canvas, viewportId, mapId, init = false) => {
  let viewport = document.querySelector(viewportId);
  let mapImg = document.querySelector(`${mapId} img`);
  let border = 4;
  if (mapImg.src.indexOf("data") === -1) {
    return;
  }
  if (init) {
    viewport.style.left = `0px`;
    viewport.style.top = `0px`;
    viewport.style.width = `${mapImg.width}px`;
    viewport.style.height = `${mapImg.height}px`;
  } else {
    let vpt = canvas.vptCoords;
    let left = (vpt.tl.x / fullWidth) * mapImg.width;
    let top = (vpt.tl.y / height) * mapImg.height;
    if (left < 0) left = 0;
    if (top < 0) top = 0;
    let w = ((vpt.br.x - vpt.tl.x) / fullWidth) * mapImg.width;
    if (w + left + border > mapImg.width) w -= w + left + border - mapImg.width;
    let h = ((vpt.br.y - vpt.tl.y) / height) * mapImg.height;
    if (h + top + border > mapImg.height) h -= h + top + border - mapImg.height;
    viewport.style.left = `${left}px`;
    viewport.style.top = `${top}px`;
    viewport.style.width = `${w}px`;
    viewport.style.height = `${h}px`;
  }
};

export { updateViewport };

import { AuthProvider } from "./auth";
import { UiProvider } from "./ui";

const ContextProvider = ({children}) => {
  return(
    <>
      <AuthProvider>
        <UiProvider>
          {children}
        </UiProvider>
      </AuthProvider>
    </>
  )
}

export default ContextProvider
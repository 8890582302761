import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Pill } from "./pill";

const PillList = ({ items, callback }) => {
  const [itemList, setItemList] = useState(items);

  useEffect(() => {
    setItemList(items);
  }, [items]);

  const removeCallback = useCallback(
    (e) => {
      let user = e.target.parentElement.dataset.user;
      let result = callback(user);
      if (result === true) {
        let idx = itemList.indexOf(user);
        itemList.splice(idx, 1);
        setItemList([...itemList]);
      }
    },
    [itemList, callback]
  );

  return (
    <Container>
      {itemList &&
        itemList.map((item) => {
          return (
            <Pill key={item} callback={removeCallback}>
              {item}
            </Pill>
          );
        })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  padding-top: 0.5rem;
`;

export { PillList };

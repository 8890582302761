import React from "react";
import styled from "styled-components";
import pencil from "../imgs/pencil_ui.png";
import { Button } from "./button";
import { useTranslation } from "react-i18next";
import { ToolButton } from "./tool_button";
import { ToolDoubleButton } from "./tool_double_button";
import { netflixRed } from "../utils/constants";

const ToolsAndTips = ({ onboarding = true, callback }) => {
  const { t } = useTranslation();

  return (
    <Container paddingBottom={onboarding === true ? "2rem" : "0"}>
      {onboarding === true ? (
        <div></div>
      ) : (
        <TipsHeader>Tips & Tricks</TipsHeader>
      )}
      <Tools>
        <Tool>
          <p>{t("tools.to_draw")}</p>
          <ToolButton type="draw"></ToolButton>
        </Tool>
        <Tool>
          <p>{t("tools.to_zoom")}</p>
          <ToolDoubleButton></ToolDoubleButton>
        </Tool>
        <Tool>
          <p>{t("tools.to_pan")}</p>
          <ToolButton type="pan"></ToolButton>
        </Tool>
      </Tools>

      <Permanent>
        <Callout>
          {t("tools.permanent_callout_1")}{" "}
          <img src={pencil} alt="Pencil Icon" />{" "}
          {t("tools.permanent_callout_2")}
          <Red>{t("tools.permanent_callout_3")}</Red>!
        </Callout>
        <Info>
          <p>{t("tools.permanent_info")}</p>
        </Info>
      </Permanent>
      <ShortcutKeyContainer>
        <ShortcutKey>
          <ShortcutBlock>
            <h3>{t("tools.shortcuts")}:</h3>
          </ShortcutBlock>
          <ShortcutBlock>
            <InnerBlock>
              <div>
                <QuickKey>{t("tools.m_key")}</QuickKey> - {t("tools.move")}
              </div>{" "}
              <div>
                <QuickKey>{t("tools.q_key")}</QuickKey> -{" "}
                {t("tools.quick_view")}
              </div>
            </InnerBlock>
            <InnerBlock>
              <div>
                <QuickKey> {t("tools.d_key")} </QuickKey> - {t("tools.draw")}
              </div>{" "}
              <div>
                <QuickKey> {t("tools.i_key")} </QuickKey> - {t("tools.info")}
              </div>
            </InnerBlock>
          </ShortcutBlock>
        </ShortcutKey>
      </ShortcutKeyContainer>
      {onboarding === true ? (
        <>
          <Reminder>{t("tools.reminder")}</Reminder>
          <Disclaimer>
            <input id="disclaimerCheckbox" type="checkbox" />
            <label htmlFor="disclaimerCheckbox">
              {t("onboarding.tools_disclaimer")}
            </label>
          </Disclaimer>
          <ContinueButton
            text={"Continue"}
            callback={callback}
            animated={true}
            bgColor={{ normal: "white", hover: netflixRed }}
            color={{ hover: "white", normal: netflixRed }}
          ></ContinueButton>
        </>
      ) : (
        <>
          <ModalReminder>{t("tools.reminder")}</ModalReminder>
          <CloseButton text={"Close"} callback={callback}></CloseButton>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: 0 auto;
  max-width: 800px;
  width: 80%;
  text-align: center;
  font-family: NetflixSansLight;
  font-size: 0.9rem;
  padding-bottom: ${(props) => props.paddingBottom};
`;

const TipsHeader = styled.h2`
  margin-bottom: 0;
  font-family: NetflixSansBold;
  font-size: 1.2rem;
  color: #141414;
  text-transform: uppercase;
`;

const Tool = styled.div`
  font-family: NetflixSansRegular;
  font-size: 1rem;
  color: #141414;
`;

const Tools = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 420px;
  margin: 0rem auto 2rem;
`;

const Permanent = styled.div`
  display: inline-block;
  width: 100%;
  font-family: NetflixSansBold;
  font-size: 0.95rem;
  color: #141414;

  img {
    display: inline;
    margin: -5px 0;
  }
`;

const Callout = styled.div`
  font-weight: bold;
  img {
    width: 1.7rem;
  }
`;

const Info = styled.div`
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  padding: 8px 0 0;
  font-family: NetflixSansLight;
  font-size: 0.875rem;
  color: #141414;
  font-weight: normal;
  line-height: 22px;
  p {
    margin: 0;
    padding: 1rem;
  }
`;

const Red = styled.span`
  color: #e50914;
`;

const ShortcutKeyContainer = styled.div`
  width: 100%;
  text-align: center;
`;
const ShortcutKey = styled.div`
  margin: 0rem auto;
  max-width: 400px;

  h3 {
    margin: 0 0 0.9rem;
    color: #7b7676;
    font-family: NetflixSansBold;
    font-size: 0.9rem;
  }
`;

const ShortcutBlock = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

const InnerBlock = styled.div`
  text-align: left;
  margin: 0 1rem;
  font-family: NetflixSansRegular;
  font-size: 1rem;
  color: #141414;
`;

const QuickKey = styled.span`
  font-family: NetflixSansMedium;
`;

const Disclaimer = styled.div`
  font-size: 0.7rem;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem auto 1.5rem;
  font-family: NetflixSansLight;
  color: #141414;
  input {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    border: none;
  }

  label {
    position: relative;
  }
`;

const Reminder = styled.div`
  max-width: 500px;
  margin: 1.5rem auto;
  text-align: center;
  font-family: NetflixSansBold;
  font-size: 0.9rem;
  line-height: 22px;
  color: #141414;
  font-weight: bold;
`;

const ModalReminder = styled(Reminder)`
  font-family: NetflixSansLight;
  font-weight: normal;
`;

const ContinueButton = styled(Button)`
  padding: 0.5rem 2.5rem;
`;

const CloseButton = styled(ContinueButton)`
  background-color: #9f9f9f;
  border-color: #9f9f9f;
  color: white;
  &:hover {
    background-color: ${netflixRed};
    border-color: ${netflixRed};
    color: white;
  }
  margin-bottom: 1rem;
`;

export { ToolsAndTips };

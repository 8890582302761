import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { Button } from "../components/button";
import { Input } from "../components/input";
import { AddUserList } from "../components/add_user_list";
import { SelectInput } from "../components/select";
import { useHistory } from "react-router";
import api from "../utils/api";
import { mobile } from "../styles/media";
import {
  colorGrayLight,
  netflixBlack,
  netflixRed,
  offWhite,
} from "../utils/constants";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getFieldData, clearInputs } from "./SetupCanvas.util";

const SetupCanvas = () => {
  const [events, setEvents] = useState([]);
  const [userList, setUserList] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();

  const canvasTypeOptions = [
    { value: "global_mural", text: t("global_mural") },
    { value: "sketch_battle", text: t("sketch_battle") },
  ];

  const fetchEvents = async () => {
    // Get Event Templates from API
    //TODO: Secure this endpoint
    let res = await fetch("/api/event");
    let json = await res.json();
    setEvents(json.items);
  };

  const eventSelectChangeCallback = useCallback((e) => {
    // Event select callback
    if (e.target.value === "") {
      // If no event selected - clear fields
      clearInputs();
      setUserList([]);
      return;
    }
    // Parse event data and populate fields
    let template = JSON.parse(e.target.value);
    document.getElementById("canvasTitle").value = template.name
      ? template.title
      : "";
    document.getElementById("canvasDescription").value = template.description
      ? template.description
      : "";
    document.getElementById("canvasTypeSelect").value = template.type
      ? template.type
      : "";
    setUserList(template.users ? template.users : []);
  }, []);

  const createCallback = useCallback(async () => {
    // Callback for creating an event
    let data = getFieldData();
    if (data.type === "") {
      // If no canvas type - post warning and  return
      toast.warn(`${t("setup_page.canvas_type_warning")}`);
      return;
    }
    data.active = true;
    try {
      // Post canvas data to API
      // TODO: Secure  endpoint
      const response = await fetch("/api/canvas", {
        method: "POST",
        mode: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.status !== 200) {
        // If bad status post error
        toast.error(`${t("setup_page.canvas_create_failed")}`);
      } else {
        // On successful creation - take the user to the Admin page
        // TODO: Add success toast and interval
        history.push("/admin");
      }
    } catch (err) {
      toast.error(`${t("setup_page.canvas_create_failed")}`);
      console.log("Error creating canvas", err);
    }
  }, [history, t]);

  const saveTemplate = useCallback(async () => {
    // Save Template Callback
    let data = getFieldData();
    if (data.type === "") {
      // If canvas type missing - post warning
      toast.warn(`${t("setup_page.canvas_type_warning")}`);
      return;
    }
    let event_data = {
      type: data.type,
      users: data.users,
      title: data.title,
      name: data.title,
      description: data.description,
    };

    if (data.event === null) {
      // If this is a new template - post to api
      const [err, response] = await api("/api/event", {
        method: "POST",
        mode: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(event_data),
      });
      if (err) {
        toast.error(`${t("setup_page.event_creation_error")}`);
        return;
      }
      let event_json = await response.json();
      data.event = event_json.id;
    } else {
      // If this event already exists - PUT update to api
      const [err, response] = await api(`/api/event/${data.event}`, {
        method: "PUT",
        mode: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(event_data),
      });
      if (err) {
        toast.error(`${t("setup_page.event_update_error")}`);
        return;
      }
      let event_json = await response.json();
      data.event = event_json.id;
    }
    // Reload events from api
    await fetchEvents();
    return data;
  }, [t]);

  useEffect(() => {
    const fetchData = async () => {
      let res = await fetch("/api/event");
      let json = await res.json();
      setEvents(json.items);
    };
    fetchData();
  }, []);

  return (
    <div>
      <Container>
        <InnerContainer>
          <InlineSelects>
            <FillSelect
              id={"canvasTypeSelect"}
              label={t("setup_page.canvas_type_label")}
              options={canvasTypeOptions}
              defaultOption={{
                value: "",
                text: t("setup_page.default_type_option"),
              }}
            ></FillSelect>
            <FillSelect
              id={"eventSelect"}
              label={t("setup_page.event_template_label")}
              options={events.map((event) => {
                return { value: JSON.stringify(event), text: event.name };
              })}
              onChangeCallback={eventSelectChangeCallback}
              defaultOption={{
                value: "",
                text: t("setup_page.event_default_option"),
              }}
            ></FillSelect>
          </InlineSelects>
          <Input
            type="text"
            id="canvasTitle"
            label="Title"
            style={{ margin: "0 auto", textAlign: "left" }}
          ></Input>
          <Input
            type="textarea"
            id="canvasDescription"
            label="Description"
            style={{
              height: "200px",
              margin: "0.5rem auto",
              textAlign: "left",
            }}
          ></Input>
          <AddUserList userList={userList}></AddUserList>
          <ButtonGroup>
            <CreateButton
              callback={createCallback}
              bgColor={{ normal: "#707070", hover: netflixRed }}
              color={{ normal: "white", hover: "white" }}
            >
              {t("setup_page.create_button")}
            </CreateButton>
            <SaveTemplateButton
              callback={saveTemplate}
              bgColor={{ normal: colorGrayLight, hover: "#707070" }}
              color={{ normal: "white", hover: "white" }}
            >
              {t("setup_page.save_template_button")}
            </SaveTemplateButton>
          </ButtonGroup>
        </InnerContainer>
      </Container>
    </div>
  );
};

const Container = styled.div`
  text-align: center;
  background-color: ${offWhite};
  padding: 6rem;
  @media ${mobile} {
    padding-top: 4rem;
  }
  /*label {
    font-family: NetflixSansBold;
    font-size: 1.2rem;
    color: ${netflixBlack};
    text-transform: uppercase;
    margin: 1rem 0;
  }
  input,
  textarea {
    border: 1px solid #cccccc;
    border-radius: 0.43rem;
    font-family: NetflixSansLight;
    font-size: 0.9rem;
  }*/
  @media ${mobile} {
    padding: 2rem;
  }
`;

const InnerContainer = styled.div`
  margin: 0 auto;
  text-align: left;
  max-width: 850px;
`;

const InlineSelects = styled.div`
  display: flex;
  flex: wrap;
  gap: 3.125rem;
`;

const FillSelect = styled(SelectInput)`
  flex-grow: 1;
`;

const ButtonGroup = styled.div``;

const CreateButton = styled(Button)`
  width: 10rem;
  border-color: #707070;
  :hover {
    border-color: ${netflixRed};
  }
`;

const SaveTemplateButton = styled(CreateButton)`
  border-color: ${colorGrayLight};
  :hover {
    border-color: #707070;
  }
  margin-left: 0.5rem;
`;

export { SetupCanvas };

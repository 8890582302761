import { fabric } from "fabric";

function createCanvas(canvasID, properties) {
  if (properties == null) {
    properties = {
      backgroundColor: "white",
      selection: false,
      selectionColor: "rgba(0, 0, 0, 0)",
      selectionBorderColor: "rgba(0, 0, 0, 0)",
      fireRightClick: true,
      fireMiddleClick: true,
      stopContextMenu: true,
      defaultCursor: "grab",
      renderOnAddRemove: true,
    };
  }
  let canvas = new fabric.Canvas(canvasID, properties);

  // Create and use PencilBrush with decimate value to smooth paths
  //canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
  canvas.freeDrawingBrush.decimate = 4.0;
  canvas.freeDrawingBrush.width = 0.5;
  canvas.freeDrawingBrush.color = "red";

  // Turn of selectable objects
  canvas.on("object:added", (e) => {
    e.target.set("selectable", false);
  });

  return canvas;
}

export { createCanvas };

import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

//import { config } from "./config";

var firebaseConfig = {
  apiKey: "AIzaSyD_eXifgH8llDfCyj3XVSXaKG-v-fDWT2w",
  authDomain: "drawntogether.firebaseapp.com",
  databaseURL: "https://drawntogether-default-rtdb.firebaseio.com",
  projectId: "drawntogether",
  storageBucket: "drawntogether.appspot.com",
  messagingSenderId: "267346922992",
  appId: "1:267346922992:web:073291ad5aafa1dfed2135",
  measurementId: "G-Q73CTJ3G0P",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const db = firebase.database();
const firestoreDb = firebase.firestore();
const storage = firebase.storage();

if (process.env.REACT_APP_TEST_DB) {
  db.useEmulator("localhost", 9000);
  firestoreDb.useEmulator("localhost", 9000);
}

export { app, db, firestoreDb, storage };

import api from "./api";

const checkUser = async (user) => {
  let userDoc;
  try {
    const [err, response] = await api(`/api/user/${user.uid}`);
    if (err) {
      let data = {
        email: user.email,
        id: user.uid,
      };
      const [createErr, createResponse] = await api(`/api/user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      userDoc = await createResponse.json();
      if (createErr) {
        console.log("Check user error", createErr);
      }
    } else {
      userDoc = await response.json();
    }
  } catch (e) {
    console.log("Error getting or making user", e);
  }
  return userDoc;
};

export { checkUser };

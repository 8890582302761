import React from "react";
import { ToolButton } from "./tool_button";
import { ToolDoubleButton } from "./tool_double_button";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { mobile, smallDesktop, tablet } from "../styles/media";
import { Button } from "./button";
import { netflixRed } from "../utils/constants";

const ToolDrawer = ({
  mode,
  view,
  isArtist,
  drawMode,
  openQuickView,
  panMode,
  toggleDebug,
  openScratchpad,
  openTips,
}) => {
  const { t } = useTranslation();
  return (
    <Drawer id="toolDrawer">
      {isArtist && (
        <FloatLeft>
          <TipsButton
            id="tipsButton"
            callback={openTips}
            bgColor={{ normal: "white", hover: "#616161" }}
            color={{ normal: "#616161", hover: "white" }}
          >
            ?
          </TipsButton>
        </FloatLeft>
      )}

      <OuterGroup>
        <ToolGroup>
          {isArtist && (
            <ToolButton
              type={"draw"}
              size={62}
              callback={drawMode}
              isSelected={mode === "draw"}
              id="drawButton"
            ></ToolButton>
          )}
          <TextDoubleButton
            size={62}
            textLeft={t("tools.quick_view")}
            leftId="quickViewBtn"
            textRight={t("scratchpad.heading")}
            rightId="scratchpadBtn"
            callbackLeft={openQuickView}
            callbackRight={openScratchpad}
          ></TextDoubleButton>
          <ToolButton
            type={"pan"}
            size={62}
            callback={panMode}
            isSelected={mode === "pan"}
            id="panButton"
          ></ToolButton>
        </ToolGroup>
        <ToolDoubleButton id="zoomButtons" size={62}></ToolDoubleButton>
        <Minimap id="minimap">
          <Viewport id="viewport"></Viewport>
          <img
            alt="Viewport"
            src=""
            style={{ height: "100%", width: "auto", pointerEvents: "none" }}
          ></img>
        </Minimap>
      </OuterGroup>
      {isArtist && (
        <FloatRight>
          <ToolButton
            type={"text"}
            text={"Debug"}
            size={62}
            callback={toggleDebug}
          ></ToolButton>
        </FloatRight>
      )}
    </Drawer>
  );
};

const Drawer = styled.div`
  display: flex;
  bottom: 0;
  width: 100%;
  position: fixed;
  bottom: 4rem;
  margin: 0 auto;
  gap: 8%;
  justify-content: center;
  user-select: none;
  @media ${smallDesktop} {
    gap: 2rem;
  }
  @media ${tablet} {
    button {
      display: none;
    }
    #zoomButtons {
      display: none;
    }
  }

  @media ${mobile} {
    bottom: 1rem;
    justify-content: flex-start;
  }
`;

const FloatLeft = styled.div``;

const TipsButton = styled(Button)`
  width: 62px;
  height: 62px;
  font-family: NetflixSansMedium;
  font-size: 1.25rem;
  border-radius: 50%;
  border: 0px;
  box-shadow: 0px 3px 6px #00000029;
  :hover {
    background-color: #dedede;
    color: #7b7676;
  }
  &.selected {
    background-color: #616161 !important;
    color: white !important;
  }
`;

const TextDoubleButton = styled(ToolDoubleButton)`
  background-color: white;
  button {
    font: normal normal 10px/13px NetflixSansMedium;
    color: #7b7676;
    text-align: center;
    padding: 0 5px;
  }
`;

const OuterGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 3.25rem;
  @media ${smallDesktop} {
    gap: 1.75rem;
  }
  @media ${tablet} {
    gap: 1rem;
  }
`;
const ToolGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  @media ${smallDesktop} {
    gap: 0.75rem;
  }
  @media ${tablet} {
    gap: 0.5rem;
  }
`;

const FloatRight = styled.div`
  position: absolute;
  display: flex;
  right: 1rem;
`;

const Minimap = styled.div`
  height: 4rem;
  width: auto;
  margin: 0 0.5rem;
  position: relative;
  cursor: default;
  img {
    user-select: none;
  }
`;

const Viewport = styled.div`
  border: 2px black solid;
  width: 0;
  height: 0;
  position: absolute;
  cursor: grab;
  &.active {
    border-color: ${netflixRed};
  }
`;

export { ToolDrawer };

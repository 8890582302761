import React, { useCallback, useRef, useState, useEffect } from "react";
import styled from "styled-components";
import minusIcon from "../imgs/Icons_Minus.png";
import hoverMinus from "../imgs/Icons_Hover-Minus.png";
import plusIcon from "../imgs/Icons_Plus.png";
import hoverPlus from "../imgs/Icons_Hover-Plus.png";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const AddUserList = ({ userList = [] }) => {
  /* Add User Component for the Setup Canvas Page. 
  - Handles input, adding, and removing users, as well as simple validation
  */
  const [users, setUsers] = useState(userList);
  const userRef = useRef(userList);
  const { t } = useTranslation();

  const addUser = useCallback(
    (e) => {
      e?.preventDefault();
      let input = document.getElementById("userEmail");
      if (input.value === "") return;
      let emails = input.value.replaceAll(" ", "").split(",");
      emails = emails.filter((e) => e !== "");
      for (let email of emails) {
        if (!/^[^@]+@\w+(\.\w+)+\w$/.test(email)) {
          toast.warn(`${email} - ${t("add_user_list.invalid_email")}`);
          return;
        }
      }
      userRef.current = userRef.current.concat(emails);
      // Unpack array so React reads it as a new array and re-renders
      setUsers([...userRef.current]);
      input.value = "";
    },
    [t]
  );

  const UserItems = ({ user }) => {
    return (
      <ListItemGroup key={user} style={{ position: "relative" }}>
        <ListItem>{user}</ListItem>
        <ListInlineButton
          onClick={removeUser}
          data-value={user}
          icon={minusIcon}
          hoverIcon={hoverMinus}
        ></ListInlineButton>
      </ListItemGroup>
    );
  };

  const removeUser = useCallback((event) => {
    let index = userRef.current.indexOf(event.target.dataset.value);
    userRef.current.splice(index, 1);
    setUsers([...userRef.current]);
  }, []);

  useEffect(() => {
    setUsers(userList);
    userRef.current = userList;
  }, [userList]);

  return (
    <Container>
      <label htmlFor="userEmail">{t("add_user_list.label")}</label>
      <SelectContainer style={{ position: "relative" }}>
        <UserForm onSubmit={addUser}>
          <InlineInput
            type={"text"}
            id={"userEmail"}
            placeholder={t("add_user_list.placeholder")}
          ></InlineInput>
          <InlineButton
            onClick={addUser}
            icon={plusIcon}
            hoverIcon={hoverPlus}
          ></InlineButton>
        </UserForm>
      </SelectContainer>
      <ListContainer id="userList">
        {users &&
          users.map((user) => {
            return <UserItems key={user} user={user}></UserItems>;
          })}
      </ListContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  margin-top: 1.5rem;
`;

const SelectContainer = styled.div`
  margin-top: 1rem;
`;

const UserForm = styled.form``;

const InlineInput = styled.input`
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border-radius: 0.43rem 0.43rem 0 0 !important;
  font-family: NetflixSansRegular;
  font-size: 1rem;
  :focus {
    outline: none;
  }
`;

const InlineButton = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: none;
  background-image: url(${(props) => props.icon});
  width: 24px;
  height: 24px;
  border-image: none;
  :hover {
    background-image: url(${(props) => props.hoverIcon});
    cursor: pointer;
  }
`;

const ListInlineButton = styled(InlineButton)`
  right: 7px;
`;

const ListContainer = styled.div`
  margin-bottom: 1.5rem;
`;

const ListItemGroup = styled.div`
  border-radius: 0;
  border-style: solid;
  border-color: #cccccc;
  border-width: 0 1px 1px 1px;
  &:last-of-type {
    border-radius: 0 0 0.43rem 0.43rem;
  }
`;

const ListItem = styled.li`
  list-style: none;
  padding: 0.5rem 1rem;
  background-color: #f7f7f7;
  margin: 0;
  border-radius: 0 0 0.43rem 0.43rem;
`;

export { AddUserList };

import React from "react";
import styled from "styled-components";
import { netflixRed } from "../utils/constants";

const Button = ({
  text,
  callback,
  bgColor = { normal: netflixRed, hover: "white" },
  color = { normal: "white", hover: netflixRed },
  children,
  animated = false,
  ...styledProps
}) => {
  return (
    <NiceButton
      onClick={callback}
      bgColor={bgColor}
      color={color}
      animated={animated}
      {...styledProps}
    >
      {text}
      {children}
    </NiceButton>
  );
};

const NiceButton = styled.button`
  background-color: ${(props) => props.bgColor.normal};
  border: 2px solid ${netflixRed};
  font-family: NetflixSansBold;
  font-size: 11px;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  color: ${(props) => props.color.normal};
  letter-spacing: 0.5px;
  position: relative;
  transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 5;
  overflow: hidden;
  :hover {
    cursor: pointer;
    color: ${(props) => props.color.hover};
    ${(props) => {
      if (!props.animated) return `background-color: ${props.bgColor.hover}`;
    }}
  }
  ${(props) => {
    if (props.animated)
      return `
  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: ${props.bgColor.hover};
    color: ${props.color.hover};
    top: 0;
    left: -100%;
    transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: -1;
  }
  :hover::before {
    left: 0;
  }`;
  }}

  a {
    width: 100%;
    height: 100%;
  }
`;

export { Button };

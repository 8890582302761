export const fullWidth = 2133; //2048
export const height = 400; //384;
export const maxWidth = 800;
export const zoomFactor = 100;
export const throttleValue = 100;

export const colorGrayLight = "#dedede";
export const colorGrayDark = "#9f9f9f";
export const colorWhite = "white";
export const netflixRed = "#E40914";
export const onGreen = "#408B00";
export const offWhite = "#FAFAFA";
export const netflixBlack = "#141414";

import React from "react";
import styled from "styled-components";
import { netflixBlack } from "../utils/constants";

const SelectInput = ({
  id,
  label,
  options,
  defaultOption,
  onChangeCallback,
  ...styledProps
}) => {
  return (
    <Container {...styledProps}>
      {label && (
        <Label htmlFor={id} defaultValue={defaultOption}>
          {label}
        </Label>
      )}
      <Select
        id={id}
        onChange={onChangeCallback}
        defaultValue={defaultOption.value}
      >
        {defaultOption && (
          <option value={defaultOption.value}>{defaultOption.text}</option>
        )}
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          );
        })}
      </Select>
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  &::after {
    content: "▼";
    font-size: 1rem;
    top: 4rem;
    right: 1rem;
    position: absolute;
  }
`;

const Label = styled.label`
  display: block;
  font-family: NetflixSansBold;
  font-size: 1.2rem;
  color: ${netflixBlack};
  text-transform: uppercase;
  margin: 1rem 0;
`;

const Select = styled.select`
  padding: 0.5rem;
  border-radius: 0.25rem;
  margin: 0;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 0.43rem;
  font-family: NetflixSansLight;
  font-size: 0.9rem;
  -webkit-appearance: none;
  appearance: none;

  :focus {
    outline: none;
  }
`;

export { SelectInput };

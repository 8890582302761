const userListClickCallback = (event, userListQuery, canvasRef) => {
  const user = event.target.dataset.value;
  const selected = event.target.getAttribute("selected") === "true";
  if (selected) {
    event.target.classList.remove("highlight");
    event.target.setAttribute("selected", false);
  } else {
    document.querySelectorAll(userListQuery).forEach((item) => {
      item.classList.remove("highlight");
      item.setAttribute("selected", false);
      if (item.dataset.value === user) {
        item.classList.add("highlight");
        item.setAttribute("selected", true);
      }
    });
  }
  canvasRef.getObjects().forEach((obj) => {
    obj.set("stroke", "black");
    obj.set("strokeWidth", 1);
    if (obj.username === user && !selected) {
      obj.set("stroke", "red");
      obj.set("strokeWidth", 3);
    }
  });
  canvasRef.requestRenderAll();
};

const canvasUserClickCallback = (event, userListQuery, canvasRef) => {
  if (event.target && event.target.username) {
    console.log(event.target);
    event.target.dataset = {};
    let username = event.target.username;
    let user = document.querySelector(`li[data-value=${username}]`);
    event.target = user;
    event.target.dataset.value = username;
    userListClickCallback(event, userListQuery, canvasRef);
  }
};

export { userListClickCallback, canvasUserClickCallback };

import React, { useContext, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import logoSymbol from "../imgs/Netflix_Symbol_PMS.png";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/auth";
import { UiContext } from "../context/ui";
import { app } from "../services/firebase";
import { mobile, smallDesktop, tablet } from "../styles/media";
import Menu from "react-burger-menu/lib/menus/slide";
import "./header.css";
import { netflixRed } from "../utils/constants";
import { Button } from "./button";

const Header = ({ selected = "" }) => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);

  const links = [
    { link: "admin", name: "Admin" },
    { link: "admin/setup", name: "Setup Canvas" },
    { link: "admin/archive", name: "Archive" },
  ];
  const { user, isAdmin, isArtist } = useContext(AuthContext);
  const {
    headerHeight: height,
    showHeaderLinks: showLinks,
    showAdminLinks,
  } = useContext(UiContext);
  const path = useLocation().pathname;

  const closeMenu = useCallback(() => {
    setOpenMenu(false);
  }, []);

  const stateChange = useCallback((state) => {
    setOpenMenu(state.isOpen);
  }, []);

  const getDisplayName = () => {
    let [first, last] = user.displayName.split(" ");
    let displayName = first;
    if (last) {
      displayName += ` ${last[0]}.`;
    }
    return displayName;
  };

  const showProfile = useCallback((e) => {
    //e.stopPropagation();
    let profile = document.getElementById("profile");
    //if (profile.style.display === "none") {
    //profile.style.display = "block";
    profile.classList.add("show");
    //document.addEventListener("click", hideProfile);
    //} else {
    //  hideProfile();
    //}
  }, []);

  const hideProfile = useCallback(() => {
    //document.getElementById("profile").style.display = "none";
    document.getElementById("profile").classList.remove("show");
    //document.removeEventListener("click", hideProfile);
  }, []);

  return (
    <div id="headerWrap">
      <MobileLogo>
        <Link to="/">
          <img src={logoSymbol} alt="Logo"></img>
        </Link>
      </MobileLogo>
      <IdentityBar>
        <MobileMenu isOpen={openMenu} onStateChange={stateChange}>
          <Link to="/" className={"menu-item"} onClick={closeMenu}>
            {t("home_link")}
          </Link>
          <Link to="/explore" className={"menu-item"} onClick={closeMenu}>
            {isArtist ? t("explore_and_sketch") : t("explore")}
          </Link>
          {isAdmin &&
            links.map((value, i) => {
              return (
                <Link
                  to={`/${value.link}`}
                  key={value.name}
                  onClick={closeMenu}
                >
                  {value.name}
                </Link>
              );
            })}
        </MobileMenu>

        <User
          color={isAdmin ? netflixRed : "#141414"}
          onMouseOver={showProfile}
        >
          <UserName>{getDisplayName() || "Sign Out"}</UserName>
          {user.photoURL && <PhotoBubble src={user.photoURL} />}
        </User>
        <Profile id="profile" onMouseLeave={hideProfile}>
          <LargePhotoBubble src={user.photoURL}></LargePhotoBubble>
          <FullUsername>{user.displayName}</FullUsername>
          <UserEmail>{user.email}</UserEmail>
          <SignOutButton
            color={{ normal: netflixRed, hover: "white" }}
            bgColor={{ normal: "white", hover: netflixRed }}
            callback={() => app.auth().signOut()}
            animated={true}
          >
            {t("sign_out")}
          </SignOutButton>
        </Profile>
      </IdentityBar>

      <Nav
        id="navbar"
        role="navigation"
        bgColor={"white"}
        style={{ "--height": height }}
      >
        <Logo id="logoBlock">
          <Link to="/">
            <img src={logoSymbol} alt="Logo"></img>
            <Branding> {t("drawn_together")} </Branding>
          </Link>
        </Logo>

        {showLinks && (
          <li>
            <Link
              to="/explore"
              className={
                ["/explore", "/onboarding"].includes(path) ? "selected" : ""
              }
            >
              {isArtist ? t("explore_and_sketch") : t("explore")}
            </Link>
          </li>
        )}
        {isAdmin &&
          showAdminLinks &&
          links.map((value, i) => {
            return (
              <li key={value.name}>
                <AdminLink
                  to={`/${value.link}`}
                  className={`/${value.link}` === path ? "selected" : ""}
                >
                  {value.name}
                </AdminLink>
              </li>
            );
          })}
      </Nav>
    </div>
  );
};

const Nav = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0rem;
  box-sizing: border-box;
  overflow: hidden;
  min-height: var(--height);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  z-index: 200;
  background-color: white;

  position: relative;
  li {
    float: left;
  }

  li {
    a {
      display: block;
      color: #141414;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      padding: 0 1rem;
      font-size: 0.875rem;
      letter-spacing: -0.5px;
      img {
        display: block;
      }
    }
    &:first-of-type {
      a {
        padding: 0 1rem 0 0;
        font-family: NetflixSansBold;
        margin-left: 4rem;
      }

      @media ${smallDesktop} {
        a {
          margin-left: 3rem;
        }
      }
      @media ${tablet} {
        a {
          margin-left: 2rem;
        }
      }
    }
  }

  img {
    max-width: 4rem;
  }

  .selected {
    color: ${netflixRed};
  }

  @media ${mobile} {
    display: none;
  }
`;

const IdentityBar = styled.div`
  width: 100%;
  height: 2.125rem;
  position: relative;
  background-color: white;
  border-bottom: 1px solid #dedede;
  @media ${mobile} {
    height: 3rem;
  }
`;

const User = styled.div`
  cursor: pointer;
  color: ${(props) => props.color};
  position: absolute;
  top: 0.3rem;
  right: 1.5rem;
  @media ${mobile} {
    top: 0.75rem;
    right: 1rem;
  }
`;

const UserName = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-family: NetflixSansBold;
  font-size: 0.75rem;
  text-transform: uppercase;
  @media ${mobile} {
    display: none;
  }
`;

const PhotoBubble = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  vertical-align: middle;
  margin-left: 0.5rem;
`;

const Profile = styled.div`
  background-color: white;
  width: 388px;
  text-align: center;
  position: absolute;
  top: 2rem;
  right: 0;
  box-shadow: 0px 3px 6px #00000010;
  border-radius: 0px 0px 7px 7px;
  z-index: 500;
  padding: 0;
  height: 0;
  overflow: hidden;
  @media ${mobile} {
    top: 3rem;
  }
  transition: all 0.5s;
  &.show {
    height: 224px;
    padding: 2rem 0;
  }
`;

const FullUsername = styled.p`
  font-family: NetflixSansBold;
  font-size: 1.25rem;
  color: #141414;
  margin: 1rem 0 0;
`;

const UserEmail = styled.p`
  font-family: NetflixSansLight;
  font-size: 1rem;
  color: #7b7676;
  margin: 0.25rem 0 1.25rem;
`;

const LargePhotoBubble = styled.img`
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
`;

const SignOutButton = styled(Button)`
  width: 50%;
`;

const Logo = styled.div`
  float: left;
  margin: 0 0 0 4.5rem;
  width: 15%;
  max-width: 16rem;
  position: relative;
  ::after {
    content: "";
    height: 60%;
    width: 1px;
    position: absolute;
    right: 0;
    top: 20%;
    background-color: #dedede;
  }

  img {
    display: block;
    max-width: 2.75rem;
    float: left;
  }

  @media ${smallDesktop} {
    margin: 0 0 0 2rem;
    width: 20%;
  }
  @media ${tablet} {
    margin: 0 0 0 1rem;
  }
`;

const Branding = styled.div`
  font-family: NetflixSansBold;
  font-size: 19px;
  color: #141414;
  text-transform: uppercase;
  text-decoration: none;
  float: left;
  letter-spacing: -0.5px;
  max-width: 105px;
  line-height: 21px;
  margin: 1.2rem 0 0 0.45rem;
`;

const MobileLogo = styled.div`
  display: none;
  @media ${mobile} {
    display: block;
    margin: 0;
    position: absolute;
    width: 100%;
  }
  a {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  img {
    max-width: 2rem;
    text-align: center;
  }
`;
const MobileMenu = styled(Menu)`
  display: none;
  font-family: NetflixSansRegular;
  @media ${mobile} {
    display: block;
  }
`;

const AdminLink = styled(Link)`
  :hover {
    color: ${netflixRed} !important;
  }
`;

export { Header };

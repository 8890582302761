import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { app } from "../services/firebase";
import api from "../utils/api";
import { activeCanvas } from "../utils/active_canvas";

const AuthContext = React.createContext({
  user: {
    displayName: "Test User",
  },
  isArtist: true,
  isAdmin: false,
});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isArtist, setIsArtist] = useState(false);
  const [failure, setFailure] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    app.auth().onAuthStateChanged((u) => (u ? verifyUser() : setUser(null)));
    const unblock = history.block((_) => {
      const validSession = checkSession(24, "lastSignInTime");
      if (!validSession) app.auth().signOut();
      return validSession;
    });
    return () => {
      app.auth().signOut();
      unblock();
    };
  }, [history]);

  const checkSession = (hours, type) => {
    const u = app.auth().currentUser;
    if (!u) return false;
    const prevAuth = +new Date(u.metadata[type]);
    const now = +new Date();
    const ms = now - prevAuth;
    return ms < hours * 60 * 60 * 1000; // lastSignInTime requires minimum 2 mins? https://ask.csdn.net/questions/6686504
  };

  const verifyUser = async () => {
    const idToken = await app.auth().currentUser.getIdToken();
    let userObj;
    // Verify SSO User
    try {
      const [err, response] = await api("/admin/verify", {
        method: "GET",
        headers: {
          "X-Access-Token": idToken,
        },
      });

      //if (err) throw err;

      const r = await response.json();
      if (!r.isAdmin && location.pathname.match(/admin/)) history.push("/");
      userObj = app.auth().currentUser;
      setUser(userObj);
      setFailure(false);
      setIsAdmin(r.isAdmin);
    } catch (e) {
      app.auth().currentUser.delete();
      app.auth().signOut();
      setFailure(true);
    }

    const canvas = await activeCanvas();
    // Check for Battle Mode and Restrict Artist Role Based on Event Users
    if (canvas && canvas.type !== "global_mural" && canvas.event !== null) {
      const [err, response] = await api(`/api/event/${canvas.event}`);
      if (err) throw err;
      const eventDoc = await response.json();
      if (eventDoc.users.indexOf(userObj.email) !== -1) {
        setIsArtist(true);
      } else {
        setIsArtist(false);
      }
    } else {
      setIsArtist(true);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAdmin,
        isArtist,
        failure,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };

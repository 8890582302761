import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react";
import { useParams, useLocation } from "react-router";
import { createCanvas } from "../utils/create_canvas";
import api from "../utils/api";
import styled from "styled-components";
import { toLocalTime } from "../utils/timestamps";
import { storage } from "../services/firebase";
import { onResizeFull } from "../utils/resize";
import { fullWidth, height, netflixRed } from "../utils/constants";
import { UiContext } from "../context/ui";
import { UserList } from "../components/user_list";
import {
  canvasUserClickCallback,
  userListClickCallback,
} from "../utils/user_list_click_callback";
import { mobile, smallDesktop } from "../styles/media";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { archiveCanvas } from "../utils/archive_canvas";
import { Button } from "../components/button";
import { app } from "../services/firebase";
import { Loading } from "../components/loading";

const ArchiveCanvas = () => {
  // Archived Canvas Page - for viewer to review and interact with
  // archived canvases
  const [canvasDoc, setCanvasDoc] = useState(null);
  const [ready, setReady] = useState(false);
  const { id } = useParams();
  let location = useLocation();
  const canvasRef = useRef(null);
  const initialized = useRef(false);
  const { headerHeight } = useContext(UiContext);
  const [usersList, setUsersList] = useState([]);
  const maxWidth = 1586;
  const { t } = useTranslation();

  const types = {
    global_mural: t("global_mural"),
    sketch_battle: t("sketch_battle"),
  };

  const userClickWrapper = useCallback((event) => {
    // User List Callback - to highlight on canvas
    userListClickCallback(event, "#userList li", canvasRef.current);
  }, []);

  const resizeCallback = useCallback(() => {
    // Window Resize Callback Wrapper
    let padding = 48;
    if (window.innerWidth > 1586) {
      padding = 0;
    }
    let h = (height * maxWidth) / fullWidth;
    onResizeFull(
      maxWidth,
      h,
      canvasRef.current,
      initialized,
      () => {},
      padding
    );
    canvasRef.current.setZoom(canvasRef.current.width / fullWidth);
  }, []);

  const archiveButtonCallback = useCallback(async () => {
    // Archive Button - use this  to re-trigger archive process in case the image failed to render
    const userToken = await app.auth().currentUser.getIdToken();
    archiveCanvas(userToken, id, t("archive_canvas_page.archive_canvas"));
  }, [id, t]);

  const load = useCallback(async () => {
    // Set up function - load canvas and user data
    let canvasData = null;
    if (location.canvas === undefined) {
      const [err, res] = await api(`/api/canvas/${id}`);
      if (err !== null) {
        toast.error(`${t("archive_canvas_page.load_data_error")}`);
        setReady(true);
        return;
      }
      const json = await res.json();
      canvasData = json;
    } else {
      canvasData = location.canvas;
    }
    setCanvasDoc(canvasData);
    let httpRef = await storage.refFromURL(canvasData.json).getDownloadURL();
    let xhr = new XMLHttpRequest();
    xhr.responseType = "text";
    xhr.onload = (event) => {
      // Load json file from storage and create canvas
      canvasRef.current = createCanvas("archivedCanvas");
      canvasRef.current.loadFromJSON(xhr.response);
      canvasRef.current.renderAll();
      canvasRef.current.perPixelTargetFind = true;
      let localUserList = [];
      canvasRef.current.getObjects().forEach((obj) => {
        localUserList.push(obj.username);
      });
      let s = [...new Set(localUserList)];
      setUsersList(s);
      canvasRef.current.on("mouse:down", (e) => {
        canvasUserClickCallback(e, "#userList li", canvasRef.current);
      });
      canvasRef.current.on("mouse:over", (e) => {
        if (e.target?.username) {
          document
            .querySelector(`li[data-value=${e.target.username}]`)
            .classList.add("highlight");
        }
      });
      canvasRef.current.on("mouse:out", (e) => {
        if (e.target?.username) {
          let u = document.querySelector(`li[data-value=${e.target.username}]`);
          if (
            u.getAttribute("selected") === null ||
            u.getAttribute("selected") === "false"
          ) {
            u.classList.remove("highlight");
          }
        }
      });

      resizeCallback();
      setReady(true);
    };
    xhr.open("GET", httpRef);
    xhr.send();
  }, [id, location.canvas, resizeCallback, t]);

  useEffect(() => {
    load();
    window.addEventListener("resize", resizeCallback);
    return () => {
      if (canvasRef.current) {
        canvasRef.current.dispose();
      }
      window.removeEventListener("resize", resizeCallback);
    };
  }, [load, resizeCallback]);

  return (
    <Container {...{ headerHeight }}>
      {canvasDoc && (
        <Panel>
          <DescriptionBlock>
            <Description>
              <b>{t("archive_canvas_page.title")}: </b>
              {canvasDoc.title === "" ? t("untitled") : canvasDoc.title}
            </Description>
            <Description>
              <b>ID: </b>
              {canvasDoc.id}
            </Description>
            <Description>
              <b>{t("archive_canvas_page.description")}: </b>
              {canvasDoc.description}
            </Description>
            <Description>
              <b>{t("archive_canvas_page.completed")}: </b>
              {canvasDoc.completed ? "True" : "False"}
            </Description>
            <Description>
              <b>{t("archive_canvas_page.timestamp")}: </b>
              {toLocalTime(canvasDoc.timestamp)}
            </Description>
            <Description>
              <b>{t("archive_canvas_page.type")}: </b>
              {types[canvasDoc.type]}
            </Description>
            <Description>
              <Button
                text={t("archive_canvas_page.archive")}
                callback={archiveButtonCallback}
              ></Button>
            </Description>
          </DescriptionBlock>
        </Panel>
      )}
      {!ready && <Loading></Loading>}
      {canvasDoc === null && ready && <p>Canvas does not exist</p>}
      <canvas id="archivedCanvas"></canvas>
      <Panel>
        <PanelTitle>{t("archive_canvas_page.users")}</PanelTitle>
        {usersList && (
          <UserList
            id="userList"
            userData={usersList}
            callback={userClickWrapper}
          ></UserList>
        )}
      </Panel>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem;
  @media ${mobile} {
    padding-top: 4rem;
  }

  max-width: 1586px;
  margin: 0 auto;
  #archivedCanvas {
    box-shadow: 0px 3px 6px #00000029;
  }

  .highlight {
    background-color: ${netflixRed} !important;
    transition: 100ms;
    color: white;
    padding: 0 0.5rem;
    border-radius: 0.25rem;
  }
`;

const DescriptionBlock = styled.div`
  padding: 1rem;
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
`;

const PanelTitle = styled.h2`
  margin: 0 auto;
  font-family: NetflixSansBold;
  font-size: 1.2rem;
  text-transform: uppercase;
`;

const Panel = styled.div`
  margin: 2rem auto;
  background-color: white;
  padding: 2.18rem 2.6rem;
  border-radius: 0.5rem;
  flex-grow: 2;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;

  ul {
    padding: 0;
  }
  li {
    line-height: 1.5rem;
    font-family: NetflixSansLight;
    font-size: 0.9rem;
    b {
      font-family: NetflixSansMedium;
      font-weight: normal;
    }
  }

  @media ${smallDesktop} {
    padding: 1.5rem 2rem;
  }
`;

export { ArchiveCanvas };

const pathProps = (username, perpixel = false) => {
  return {
    stroke: "rgba(0,0,0)",
    fill: null,
    strokeLineCap: "round",
    strokeLineJoin: "round",
    strokeMiterLimit: 10,
    strokeWidth: 0.5,
    strokeDashArray: null,
    perPixelTargetFind: perpixel,
    selectable: false,
    username: username,
  };
};

export { pathProps };

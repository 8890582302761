import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";
import { DescriptionBlock } from "../components/descriptionblock";
import { mobile, smallDesktop, tablet } from "../styles/media";
import { activeCanvas } from "../utils/active_canvas";
import { Loading } from "../components/loading";
import { createCanvas } from "../utils/create_canvas";
import { fabric } from "fabric";
import { onResizeFull } from "../utils/resize";
import { relativeStart } from "../utils/timestamps";
import { strokeListener, systemDocListener } from "../utils/rtdbListeners";
import { pathProps } from "../utils/path_props";
import { fullWidth, height, netflixRed } from "../utils/constants";
import fsIcon from "../imgs/FullScreenIconx2.png";
import { Calendar } from "../icons/calendar";
import logoSymbol from "../imgs/Netflix_Symbol_PMS.png";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const closeFullScreen = () => {
  /* Callback to close fullscreen */
  if (document.fullscreenElement && document.exitFullscreen) {
    document.exitFullscreen();
  }
};

const Home = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState(null);
  const [ready, setReady] = useState(false);
  const resizeWidth = useRef(fullWidth);
  const [buttonPad, setButtonPad] = useState();
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const { t } = useTranslation();

  const canvas = useRef(null);
  const canvasDoc = useRef(null);
  const initialized = useRef(false);
  const listenerRef = useRef(null);
  const systemDocRef = useRef(null);
  const systemListenerRef = useRef(null);

  /* Page load setup */
  const load = async () => {
    // Get Active Canvas Data
    canvasDoc.current = await activeCanvas();

    // If there is an active canvas
    if (canvasDoc.current) {
      // Set title and description or load defaults
      if (canvasDoc.current.title !== null && canvasDoc.current.title !== "") {
        setTitle(canvasDoc.current.title);
      } else {
        setTitle(t("global_mural"));
      }
      if (
        canvasDoc.current.description !== null &&
        canvasDoc.current.description !== ""
      ) {
        setDescription(canvasDoc.current.description);
      }
      // Create listener on rtdb for new strokes
      listenerRef.current = strokeListener(
        canvasDoc.current,
        onDrawingEvent,
        () => {
          setReady(true);
        }
      );
      // Setup System Doc Listener - Listens for New Canvases
      systemListenerRef.current = systemDocListener((data) => {
        if (systemDocRef.current === null) {
          systemDocRef.current = data;
        } else if (systemDocRef.current.active_canvas !== data.active_canvas) {
          //TODO: A more elegant notification
          toast.success(t("home.new_canvas"));
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      });
    } else {
      //TODO: Add to translations
      setTitle(t("home.no_canvas_title"));
      setDescription(t("home.no_canvas_description"));
      setReady(true);
    }

    // Create FabricJS Canvas
    canvas.current = createCanvas("viewCanvas");
    canvas.current.defaultCursor = "arrow";
    canvas.current.hoverCursor = "arrow";
    // Window Resize Listener/Callback
    onResizeFull(fullWidth, height, canvas.current, initialized);
    calculateResizeWidth();
    resizeCallback();
    setHeaderHeight(document.getElementById("headerWrap")?.offsetHeight);
  };

  const onDrawingEvent = (data) => {
    /* Callback for when new strokes added to canvas 
    @param data - json entry from realtime database stroke document
    */
    let json = JSON.parse(data.path);
    // Create path adding username
    let path = new fabric.Path(json.path, pathProps(data.username));
    // Draw on Canvas
    canvas.current.add(path);
  };

  const fullScreen = () => {
    /* Function to toggle the canvas to a fullscreen element. 
    Checks that fullscreen is available (not available on mobile)
    */
    let canvasContainer = document.querySelector("#canvasContainer");
    if (
      document.fullscreenElement !== undefined &&
      !document.fullscreenElement &&
      canvasContainer.requestFullscreen
    ) {
      canvasContainer.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const resizeCallback = useCallback(() => {
    /* Hook Callback for resize - use hook so we can remove on unmount */
    onResizeFull(
      fullWidth,
      height,
      canvas.current,
      initialized,
      null,
      resizeWidth.current
    );
    setHeaderHeight(document.getElementById("headerWrap")?.offsetHeight);
  }, []);

  const calculateResizeWidth = useCallback(() => {
    if (window.innerWidth > fullWidth) {
      resizeWidth.current = 1;
      setButtonPad(16);
    } else if (window.innerWidth > 1260) {
      resizeWidth.current = 128;
      setButtonPad(80);
    } else if (window.innerWidth > 1023) {
      resizeWidth.current = 64;
      setButtonPad(48);
    } else if (window.innerWidth > 780) {
      resizeWidth.current = 32;
      setButtonPad(26);
    } else {
      resizeWidth.current = 17;
      setButtonPad(16);
    }
    console.log(resizeWidth.current);
  }, []);

  const removeListeners = () => {
    /*Remove database listeners */
    if (listenerRef.current !== null) {
      listenerRef.current.off("child_added");
    }
    // Remove Firebase Listener
    if (systemListenerRef.current) {
      systemListenerRef.current();
    }
  };

  const loadVideo = useCallback(() => {
    if (!videoLoaded) {
      document.getElementById("mp4Src").src =
        "https://storage.googleapis.com/drawntogether.appspot.com/assets/robodraw_b.mp4";
      document.getElementById("heroVideo").load();
      document.getElementById("heroVideo").play();
      document.getElementById("heroVideo").setAttribute("controls", true);
      setVideoLoaded(true);
    }
  }, [videoLoaded]);

  useEffect(() => {
    /* On page load run load function to setup all the things */
    load();
    window.addEventListener("resize", resizeCallback);
    window.addEventListener("resize", calculateResizeWidth);
    document
      .getElementById("canvasContainer")
      .addEventListener("click", closeFullScreen);
    return () => {
      window.removeEventListener("resize", resizeCallback);
      window.removeEventListener("resize", calculateResizeWidth);
      if (canvas.current !== null) {
        canvas.current.dispose();
      }
      removeListeners();
    };
  }, []);

  return (
    <Container {...{ headerHeight }}>
      {!ready && <Loading></Loading>}
      <Preview id="preview">
        <div id="canvasContainer">
          <Logo id="fsLogo">
            <img src={logoSymbol} alt="logo"></img>
            <Branding>{t("drawn_together")}</Branding>
          </Logo>
          <canvas id="viewCanvas"></canvas>
          {document.exitFullscreen && (
            <CanvasButton
              id="fsButton"
              onClick={fullScreen}
              padding={buttonPad}
              src={fsIcon}
            ></CanvasButton>
          )}
          <TitleDescription id="titleDescription" padding={buttonPad}>
            <Title title="Title">{title}</Title>
            <Description title="Description">{description}</Description>
          </TitleDescription>
          {canvasDoc.current && (
            <Timestamp id="timestamp" padding={buttonPad}>
              <Calendar size="0.75rem"></Calendar>
              {t("home.started_on")}:{" "}
              {relativeStart(canvasDoc.current.timestamp)}
            </Timestamp>
          )}
        </div>
      </Preview>
      <InfoBlock>
        <DescriptionBlock descriptionLead={""}></DescriptionBlock>
        <VideoBlock>
          <video
            id="heroVideo"
            poster="https://storage.googleapis.com/drawntogether.appspot.com/assets/poster.png"
            onClick={loadVideo}
          >
            <source id="mp4Src" type="video/mp4"></source>
          </video>
        </VideoBlock>
      </InfoBlock>
      <FaqContainer id="faqContainer">
        <FaqBlock id="faq">
          <Column>
            <Faq>
              <Question> {t("faq.q_1")} </Question>
              <Answer> {t("faq.a_1")} </Answer>
            </Faq>
            <Faq>
              <Question> {t("faq.q_2")} </Question>
              <Answer> {t("faq.a_2")} </Answer>
            </Faq>
            <Faq>
              <Question> {t("faq.q_3")} </Question>
              <Answer>
                <a href="mailto:noreply@drawntogether.com">
                  noreply@drawntogether.com
                </a>
              </Answer>
            </Faq>
          </Column>
          <Column>
            <Faq>
              <Question> {t("faq.q_4")} </Question>
              <Answer> {t("faq.a_4")} </Answer>
            </Faq>
            <Faq>
              <Question> {t("faq.q_5")} </Question>
              <Answer> {t("faq.a_5")} </Answer>
            </Faq>
            <Faq>
              <Question> {t("faq.q_6")} </Question>
              <Answer> {t("faq.a_6")} </Answer>
            </Faq>
          </Column>
        </FaqBlock>
      </FaqContainer>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  margin: 0;
  background-color: #fafafa;
  min-height: calc(100vh - ${(props) => props.headerHeight}px);
  height: 100%;
  scroll-behavior: smooth;

  #viewCanvas {
    box-shadow: 0px 3px 6px #00000029;
  }
`;

const TitleDescription = styled.div`
  float: left;
  position: absolute;
  left: ${(props) => `${props.padding - 8}px`};
  margin: 1rem 0;
  display: flex;
  height: 0.9rem;

  @media ${mobile} {
    font-size: 0.75rem;
    display: block;
    left: 0.75rem;
  }
`;

const Title = styled.div`
  color: black;
  font-family: NetflixSansBold;
  font-size: 0.9rem;
  padding: 0;
  color: #141414;
  font-weight: normal;
  margin: 0;
  line-height: 0.9rem;
  @media ${mobile} {
    text-align: left;
  }
`;

const Description = styled.div`
  color: #221f1f;
  font-size: 0.75rem;
  padding-left: 1rem;
  font-family: "Nunito Sans", sans-serif;
  max-width: 400px;
  text-align: left;
  @media ${mobile} {
    margin: 0.25rem 0;
    padding: 0;
  }
`;

const Preview = styled.div`
  margin: 0 auto;
  padding-top: 4rem;
`;

const CanvasButton = styled.img`
  position: absolute;
  right: ${(props) => `${props.padding}px`};
  bottom: 1rem;
  border: none;
  display: block;
  padding: 0;
  width: 2rem;
  height: 2rem;
  :hover {
    cursor: pointer;
  }

  @media ${tablet} {
    width: 1.5rem;
    height: 1.5rem;
    bottom: 0.5rem;
  }
`;

const Logo = styled.div`
  float: left;
  margin: 3rem 4rem;
  width: 15%;
  max-width: 15rem;
  position: relative;
  display: none;
  img {
    display: block;
    max-width: 2.25rem;
    float: left;
  }
`;

const Branding = styled.div`
font-family: NetflixSansBold;
font-size: 19px;
color: #141414;
text-transform: uppercase;
float: left;
letter-spacing; -0.5px;
max-width: 105px;
line-height: 20px;
margin-top: 0.75rem;
text-align: left;
`;

const Timestamp = styled.div`
  float: right;
  font-family: "Nunito Sans", sans-serif;
  margin: 1rem 0;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  position: absolute;
  right: ${(props) => `${props.padding - 16}px`};
  svg {
    margin-right: 0.2rem;
  }
  @media ${mobile} {
    font-size: 0.75rem;
  }
  @media ${tablet} {
    right: ${(props) => `${props.padding - 8}px`};
  }
`;

const InfoBlock = styled.div`
  display: flex;
  justify-content: center;
  margin: 7rem auto 0;
  max-width: 1620px;
  @media ${smallDesktop} {
    justify-content: space-around;
  }
  @media ${mobile} {
    text-align: center;
    display: block;
    width: 100%;
    margin: 5rem auto;
  }
`;

const VideoBlock = styled.div`
  float: left;
  width: 40%;
  video {
    max-width: 800px;
    width: 100%;
  }
  @media ${tablet} {
    width: 45%;
  }
  @media ${mobile} {
    float: none;
    width: 80%;
    margin: 0 auto;
  }
`;

const FaqContainer = styled.div`
  background-color: white;
  width: 100%;
  @media ${mobile} {
    padding-top: 2rem;
  }
  display: none;
  &.show {
    display: block !important;
  }
`;

const FaqBlock = styled.div`
  padding: 8rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  gap: 3rem;
  max-width: 1620px;
  @media ${tablet} {
    padding: 4rem;
  }
  @media ${mobile} {
    display: block;
    padding: 0;
    width: 100%;
  }
`;

const Column = styled.div`
  text-align: left;
  float: left;
  width: 50%;
  @media ${mobile} {
    float: unset;
    width: 80%;
    margin: 0 auto;
  }
`;

const Faq = styled.div`
  margin-bottom: 4rem;
`;

const Question = styled.p`
  font-family: NetflixSansBold;
  font-size: 1.2rem;
  color: #141414;
  text-transform: uppercase;
  padding: 0;
`;

const Answer = styled.p`
  font-family: NetflixSansLight;
  font-family: 1rem;
  color: #141414;
  a {
    color: ${netflixRed};
    text-decoration: none;
  }
`;

export { Home };

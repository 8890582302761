async function activeCanvas() {
  try {
    const res = await fetch(`/api/canvas?active=true`);
    const data = await res.json();
    return data.items[0];
  } catch (e) {
    return null;
  }
}

export { activeCanvas };

import { correctViewport } from "./correct_viewport";

const handleZoom = (
  canvas,
  delta,
  opt,
  limitCanvas = true,
  width,
  height,
  minZoom = 1
) => {
  let zoom = canvas.getZoom();
  if (delta !== null) {
    zoom *= 0.999 ** delta;
    if (zoom > 20) zoom = 20;
    if (zoom < minZoom) zoom = minZoom;
  }
  if (opt == null) {
    /* If not a canvas mouse event zoom from center */
    const coords = canvas.vptCoords;
    const _x = (coords.tr.x - coords.tl.x) / 2.0;
    const _y = (coords.br.y - coords.tr.y) / 2.0;
    canvas.zoomToPoint({ x: _x, y: _y }, zoom);
  } else {
    canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
    opt.e.preventDefault();
    opt.e.stopPropagation();
  }
  /* If we limit canvas size constrict viewport to that canvas size */
  if (limitCanvas) {
    correctViewport(canvas, width, height, zoom);
  }
  canvas.requestRenderAll();
};

export { handleZoom };

import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const EditableInput = ({ value, callback, id, docKey, type }) => {
  const [inputValue, setValue] = useState(value);
  const [disabled, setDisabled] = useState(true);

  const onChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const onClick = useCallback((e) => {
    setDisabled(false);
  }, []);

  const onBlur = useCallback(() => {
    setDisabled(true);
    if (callback) {
      callback(docKey, inputValue);
    }
  }, [inputValue, callback, docKey]);

  useEffect(() => {
    if (disabled === false) {
      document
        .querySelector(`#${id} ${type === "text" ? "input" : "textarea"}`)
        ?.focus();
    }
  }, [disabled, id, type]);

  return (
    <Container id={id} onClick={onClick}>
      {type === "text" ? (
        <InlineInput
          value={inputValue}
          onChange={onChange}
          disabled={disabled}
          onBlur={onBlur}
        ></InlineInput>
      ) : (
        <InlineTextArea
          value={inputValue}
          onChange={onChange}
          disabled={disabled}
          onBlur={onBlur}
        ></InlineTextArea>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0.25rem 0 0.25rem 0.5rem;
  padding: 0;
  flex-grow: 1;
`;

const Inline = `
  padding: 0 0.25rem !important;
  border: 1px solid;
  outline: none;
  font-size: 1rem;
  font-family: NetflixSansLight;
  border-radius: 0.25rem;
  background-color: #fafafa;
  border-color: #dadada;
  flex-grow: 2;
  line-height: 1.5rem;
  :disabled {
    background-color: #eaeaea;
    border-color: #bababa;
  }
  :hover {
    background-color: #fafafa;
    cursor: text;
  }
  :active{

    border-color: #dadada;
  }
`;
const InlineInput = styled.input`
  ${Inline}
`;

const InlineTextArea = styled.textarea`
  ${Inline}
  height: 1.5rem;
  width: 250px;
`;

export { EditableInput };

import React, { useState, useCallback, useContext } from "react";
import styled from "styled-components";
import { ToolsAndTips } from "./tools_and_tips";
import { Scratchpad } from "./scratchpad";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/auth";
import { UiContext } from "../context/ui";
import api from "../utils/api";
import { toast } from "react-toastify";

const Onboarding = () => {
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { headerHeight } = useContext(UiContext);

  const toolsTipsCallback = useCallback(() => {
    const checked = document.querySelector("#disclaimerCheckbox").checked;
    if (checked) {
      setPage(1);
    } else {
      toast.warn("You must agree!");
    }
  }, []);

  const scratchpadCallback = useCallback(async () => {
    await api(`/api/user/${user.uid}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ onboarded: true }),
    });
    history.push("/explore");
  }, [history, user.uid]);

  return (
    <Container headerHeight={headerHeight}>
      <Header>{t("onboarding_header")}</Header>
      {page === 0 ? (
        <ToolsAndTips
          onboarding={true}
          callback={toolsTipsCallback}
        ></ToolsAndTips>
      ) : (
        <Scratchpad
          onboarding={true}
          callback={scratchpadCallback}
        ></Scratchpad>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  text-align: center;
  background-color: #fafafa;
  position: absolute;
  bottom: 0;
  top: calc(${(props) => props.headerHeight} + 1rem);
  padding: 4rem 0;
`;

const Header = styled.h2`
  margin: 0 0 1rem;
  font-family: NetflixSansBold;
  font-size: 1.1rem;
  color: #141414;
  text-transform: uppercase;
`;

export { Onboarding };

import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";
import { activeCanvas } from "../utils/active_canvas";
import { app, db, firestoreDb } from "../services/firebase";
import {
  adminCanvasListener,
  strokeListener,
  systemDocListener,
} from "../utils/rtdbListeners";
import { createCanvas } from "../utils/create_canvas";
import { fabric } from "fabric";
import { pathProps } from "../utils/path_props";
import { onResizeFull } from "../utils/resize";
import {
  colorGrayLight,
  fullWidth,
  height,
  netflixRed,
} from "../utils/constants";
import { UserList } from "../components/user_list";
import "./Admin.css";
import {
  canvasUserClickCallback,
  userListClickCallback,
} from "../utils/user_list_click_callback";
import { smallDesktop } from "../styles/media";
import api from "../utils/api";
import { Link } from "react-router-dom";
import { ToggleButton } from "../components/toggle_button";
import { EditableInput } from "../components/editable_input";
import { PillList } from "../components/pill_list";
import { stripPunctuation } from "../utils/strip_punctuation";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Admin = () => {
  const canvasDocRef = useRef(null);
  const [canvasDoc, setCanvasDoc] = useState(null);
  const [numUsers, setNumUsers] = useState(0);
  const activeUsersRef = useRef(null);
  const canvasListenerRef = useRef(null);
  const [strokeData, setStrokeData] = useState({});
  const systemDocRef = useRef(null);
  const systemListenerRef = useRef(null);
  const [systemDoc, setSystemDoc] = useState(null);

  const [systemOn, setSystemOn] = useState(null);
  const [maintenance, setMaintenance] = useState(null);
  const systemListener = useRef(null);
  const [useListener, setUseListener] = useState(false);
  const initialized = useRef(false);

  const canvasRef = useRef(null);
  const strokeListenerRef = useRef(null);
  const usersListRef = useRef(null);
  const [usersList, setUsersList] = useState([]);
  const maxWidth = 1586;
  const [containerHeight, setContainerHeight] = useState(0);
  const { t } = useTranslation();

  const canvasDataCallback = useCallback((data, once) => {
    // Callback for canvas meta data, update stroke data and active users
    let total = 0;
    let drawn = 0;
    let localUserList = [];
    if (data) {
      Object.keys(data).forEach((key) => {
        total += 1;
        if (data[key].status === "done") {
          drawn += 1;
        }
        //if (once) {
        localUserList.push(data[key].username);
        //}
      });
    }
    setStrokeData({ total: total, drawn: drawn });
    //if (once) {
    let s = [...new Set(localUserList)];
    usersListRef.current = s;
    setUsersList(usersListRef.current);
    //}
  }, []);

  const resizeCallback = useCallback(() => {
    let padding = 48;
    if (window.innerWidth > 1586) {
      padding = 0;
    }
    let h = (height * maxWidth) / fullWidth;
    onResizeFull(
      maxWidth,
      h,
      canvasRef.current,
      initialized,
      () => {},
      padding
    );
    canvasRef.current.setZoom(canvasRef.current.width / fullWidth);
  }, []);

  const onDrawCallback = useCallback((data) => {
    // Add new drawn line to admin canvas - then if a new user, add them to user list
    let json = JSON.parse(data.path);
    let path = new fabric.Path(json.path, pathProps(data.username));
    canvasRef.current.add(path);
    if (
      usersListRef.current &&
      usersListRef.current.indexOf(data.username) === -1
    ) {
      usersListRef.current.push(data.username);
      setUsersList([...usersListRef.current]);
    }
  }, []);

  const userClickWrapper = useCallback((event) => {
    // Callback for user selection - highlight user and strokes on canvas
    userListClickCallback(event, "#userList li", canvasRef.current);
  }, []);

  const setSystemInfo = () => {
    // Convenience function to set system data
    setSystemOn(systemDocRef.current.on);
    setMaintenance(systemDocRef.current.maintenance);
    setSystemDoc(systemDocRef.current);
  };

  const toggleSystemOn = async (value) => {
    // Api call to toggle system
    if (value === systemDocRef.current.on) return;
    let data = {
      on: value,
    };
    try {
      // TODO: Secure Endpoint
      const res = await fetch("/api/system", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (res.status === 200) {
        const json = await res.json();
        systemDocRef.current = json;
        setSystemInfo();
      } else {
        toast.error(t("admin_page.system_toggle_server_error"));
      }
    } catch (e) {
      toast.error(t("admin_page.system_toggle_error"));
    }
  };

  const toggleMaintenance = async (value) => {
    // Api call to maintenance mode
    if (value === systemDocRef.current.maintenance) return;
    let data = {
      maintenance: value,
    };
    try {
      // TODO: Secure Endpoint
      const res = await fetch("/api/system", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (res.status === 200) {
        const json = await res.json();
        systemDocRef.current = json;
        setSystemInfo();
      } else {
        toast.error(t("admin_page.maintenance_toggle_server_error"));
      }
    } catch (e) {
      toast.error(t("admin_page.maintenance_toggle_error"));
    }
  };

  const setupListeners = () => {
    // Listen for Users on Active Canvas
    setUseListener(true);
    activeUsersRef.current = db.ref(
      `${process.env.REACT_APP_CANVAS_COLLECTION}/${canvasDocRef.current.id}/users`
    );
    activeUsersRef.current.on("value", (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setNumUsers(Object.keys(data).length);
      } else {
        setNumUsers(0);
      }
    });

    // Listen to Canvas
    canvasListenerRef.current = adminCanvasListener(
      canvasDocRef.current,
      canvasDataCallback
    );

    //  Set up System listener
    systemListener.current = firestoreDb
      .collection("system")
      .doc(systemDocRef.current.id)
      .onSnapshot((doc) => {
        systemDocRef.current = doc.data();
        setSystemInfo();
      });
  };

  const removeListeners = () => {
    // Remove active user, system and canvas data listener
    // NOTE: stroke listener is always on
    setUseListener(false);
    if (activeUsersRef.current) {
      activeUsersRef.current.off("value");
    }
    if (systemListener.current) {
      systemListener.current();
    }
    if (canvasListenerRef.current) {
      canvasListenerRef.current.off("value");
    }
  };

  const toggleSystemListener = (val) => {
    // Handle system listener toggle - setup/remove listeners
    if (val) {
      setupListeners();
    } else {
      removeListeners();
    }
  };

  const load = async () => {
    // Setup function to set initial data on page load

    // Get Active Canvas
    canvasDocRef.current = await activeCanvas();
    setCanvasDoc(canvasDocRef.current);
    canvasRef.current = createCanvas("adminCanvas");

    if (canvasDocRef.current) {
      // Get Active Canvas Data
      db.ref(
        `${process.env.REACT_APP_CANVAS_COLLECTION}/${canvasDocRef.current.id}/users`
      ).once("value", (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setNumUsers(Object.keys(data).length);
        } else {
          setNumUsers(0);
        }
      });

      // Get Canvas Data
      canvasListenerRef.current = adminCanvasListener(
        canvasDocRef.current,
        canvasDataCallback,
        true
      );

      strokeListenerRef.current = strokeListener(
        canvasDocRef.current,
        onDrawCallback,
        () => {}
      );
      setContainerHeight(getContainerHeight());
    }

    // Get System Status Info
    try {
      const res = await fetch("/api/system");
      systemDocRef.current = await res.json();
      setSystemInfo();
    } catch (e) {
      console.log("Error getting  system doc", e);
    }
    window.addEventListener("resize", resizeCallback);

    // Highlight users when hovering the strokes on canvas
    canvasRef.current.on("mouse:over", (e) => {
      if (e.target && e.target.username) {
        document
          .querySelector(`li[data-value=${e.target.username}]`)
          .classList.add("highlight");
      }
    });

    canvasRef.current.on("mouse:out", (e) => {
      if (e.target && e.target.username) {
        let u = document.querySelector(`li[data-value=${e.target.username}]`);
        if (
          !u.getAttribute("selected") ||
          u.getAttribute("selected") === "false"
        ) {
          u.classList.remove("highlight");
        }
      }
    });

    // Click stroke to toggle highlight
    canvasRef.current.on("mouse:down", (e) => {
      canvasUserClickCallback(e, "#userList li", canvasRef.current);
    });

    canvasRef.current.set({ defaultCursor: "default", hoverCursor: "pointer" });
    resizeCallback();

    // Setup System Doc Listener
    systemListenerRef.current = systemDocListener((data) => {
      if (systemDocRef.current === null) {
        systemDocRef.current = data;
      } else if (
        data &&
        systemDocRef.current.active_canvas !== data?.active_canvas
      ) {
        toast.success(t("admin_page.new_canvas_created"));
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    });
  };

  //const archiveCanvas = async () => {
  //  // Deprecated
  //  const userToken = await app.auth().currentUser.getIdToken();
  //  const [errors, response] = await api(
  //    `/api/canvas/${canvasDocRef.current.id}/archive`,
  //    {
  //      method: "POST",
  //      headers: {
  //        "X-Access-Token": userToken,
  //      },
  //    }
  //  );
  //  if (errors !== null) {
  //    console.log("Error Archiving Canvas");
  //  } else if (response.status == 200) {
  //    toast.success(t("archive_canvas"));
  //    setTimeout(() => {
  //      window.location.reload();
  //    }, 5000);
  //  }
  //};

  const updateCanvasData = useCallback(
    // Api calls for updating canvas data
    async (key, value) => {
      if (canvasDoc[key] === value || canvasDoc === null) {
        return;
      }
      let data = {};
      data[key] = value;
      if (key === "title") {
        let lower = value.toLowerCase();
        data["search_title"] = lower;
        data["search_terms"] = [];
        let terms = lower.split(" ");
        for (let i = 0; i < terms.length; i++) {
          let t = stripPunctuation(terms[i]);
          if (t !== "") {
            data["search_terms"].push(t);
          }
        }
      }
      // Get user token for auth
      const userToken = await app.auth().currentUser.getIdToken();
      const [errors, response] = await api(
        `/api/canvas/${canvasDocRef.current.id}`,
        {
          method: "PUT",
          headers: {
            "X-Access-Token": userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (errors !== null) {
        toast.error(t("admin_page.updaet_canvas_error"));
        return false;
      } else if (response.status === 200) {
        toast.success(t("admin_page.update_success"));
        const json = await response.json();
        setCanvasDoc(json);
        return true;
      }
    },
    [canvasDoc, t]
  );

  const addUserCallback = useCallback(async () => {
    // Verify and add users
    let u = document.getElementById("addUserInput").value;
    let users = u.replaceAll(" ", "").split(",");
    let badEmails = [];
    for (let i = 0; i < users.length; i++) {
      if (!/^[^@]+@\w+(\.\w+)+\w$/.test(users[i])) {
        toast.warn(`${t("admin_page.invalid_email")}: ${users[i]}`);
        badEmails.push(users[i]);
        users.splice(i, 1);
      } else if (canvasDoc.users.indexOf(u) > -1) {
        toast.warn(`${t("admin_page.user_exists")}: ${u}`);
        users.splice(i, 1);
      }
    }
    if (users.length > 0) {
      let updateUsers = [...users, ...canvasDoc.users];
      await updateCanvasData("users", updateUsers);
    }
    if (badEmails.length > 1) {
      document.getElementById("addUserInput").value = badEmails.join(", ");
    } else {
      document.getElementById("addUserInput").value = badEmails.join("");
    }
  }, [canvasDoc, updateCanvasData, t]);

  const removeUserCallback = useCallback(
    async (u) => {
      let updatedUsers = [...canvasDoc.users];
      const idx = updatedUsers.indexOf(u);
      updatedUsers.splice(idx, 1);
      return updateCanvasData("users", updatedUsers);
    },
    [canvasDoc, updateCanvasData]
  );

  const getContainerHeight = () => {
    /* Calculate the inner height available for canvas container */
    let windowHeight = window.innerHeight; //document.documentElement.clientHeight; //window.innerHeight;
    let navbar = document.querySelector("#headerWrap");
    let navHeight = navbar.offsetHeight;

    // On mobile we need to set a value due to the hidden nav bar
    if (navHeight === 0) {
      navHeight = 64;
    }
    return windowHeight - navHeight;
  };

  useEffect(() => {
    usersListRef.current = [];
    load();
    return () => {
      // Remove Active User Listener
      removeListeners();
      if (canvasRef.current) {
        canvasRef.current.dispose();
      }
      if (strokeListenerRef.current) {
        strokeListenerRef.current.off("child_added");
      }
      window.removeEventListener("resize", resizeCallback);
      if (systemListenerRef.current) {
        systemListenerRef.current();
      }
    };
  }, []);

  return (
    <Container containerHeight={containerHeight}>
      <ControlPanel className="admin-container">
        <Panel>
          <PanelTitle>{t("admin_page.current_canvas")}</PanelTitle>
          <PanelBody>
            {canvasDoc ? (
              <ul>
                <li>
                  <b>ID</b>: {canvasDoc.id}
                </li>
                <li>
                  <b>{t("admin_page.canvas_started")}</b>:{" "}
                  {new Date(canvasDoc.timestamp).toLocaleDateString()}{" "}
                  {new Date(canvasDoc.timestamp).toLocaleTimeString()}
                </li>
                <li>
                  <b>{t("admin_page.canvas_completed")}</b>:{" "}
                  {canvasDoc.completed ? t("yes") : t("no")}
                </li>
                <li>
                  <b>{t("admin_page.canvas_type")}</b>: {canvasDoc.type}
                </li>
                <li id="canvasTitle">
                  <Flex>
                    <b>{t("admin_page.canvas_title")}</b>:{" "}
                    <EditableInput
                      id="canvasTitle"
                      docKey="title"
                      value={canvasDoc.title}
                      callback={updateCanvasData}
                      type="text"
                    ></EditableInput>
                  </Flex>
                </li>
                <li id="canvasDescription">
                  <Flex>
                    <b>{t("admin_page.canvas_description")}</b>:{" "}
                    <EditableInput
                      id="canvasDescription"
                      docKey="description"
                      value={canvasDoc.description}
                      callback={updateCanvasData}
                      type="textarea"
                    ></EditableInput>
                  </Flex>
                </li>
                <AddUser>
                  <b>{t("admin_page.canvas_users")}</b>:{" "}
                  <AddUserInner>
                    <AddUserInput
                      id="addUserInput"
                      placeholder={t("admin_page.canvas_user_placeholder")}
                    ></AddUserInput>
                    <AddUserButton onClick={addUserCallback}>+</AddUserButton>
                  </AddUserInner>
                </AddUser>
                <PillList
                  items={canvasDoc.users}
                  callback={removeUserCallback}
                ></PillList>
              </ul>
            ) : (
              <p>
                There is currently no active Canvas. Please{" "}
                <Link to="/admin/setup">set up</Link> a canvas to activate the
                installation.
              </p>
            )}
          </PanelBody>
        </Panel>
        <Panel>
          <PanelTitle>{t("admin_page.live_stats")}</PanelTitle>
          <ToggleButton
            inState={useListener}
            idOff={"liveStatsOff"}
            idOn={"liveStatsOn"}
            callbackOff={toggleSystemListener}
            callbackOn={toggleSystemListener}
          ></ToggleButton>
          <PanelBody>
            {systemDoc && (
              <ul>
                <li>
                  <b>{t("admin_page.system_on")}</b>:{" "}
                  {systemOn ? t("on") : t("off")}
                </li>
                <li>
                  <b>{t("admin_page.maintenance_mode")}</b>:{" "}
                  {maintenance ? t("on") : t("off")}
                </li>
                <li>
                  <b>{t("admin_page.active_users")}</b>: {numUsers}
                </li>
                <li>
                  <b>{t("admin_page.strokes")}</b>: {strokeData.total}
                </li>
                <li>
                  <b>{t("admin_page.drawn")}</b>: {strokeData.drawn}
                </li>
                <li>
                  <b>{t("admin_page.percent_drawn")}</b>:{" "}
                  {strokeData.total > 0
                    ? ((strokeData.drawn / strokeData.total) * 100).toFixed(0)
                    : 0}
                  %
                </li>
              </ul>
            )}
          </PanelBody>
        </Panel>
        <Panel>
          <PanelTitle>{t("admin_page.system_controls")}</PanelTitle>
          <PanelBody>
            <ControlTitle>{t("admin_page.main_switch")}</ControlTitle>
            <ToggleButton
              inState={systemOn}
              idOff={"systemOff"}
              idOn={"systemOn"}
              callbackOff={toggleSystemOn}
              callbackOn={toggleSystemOn}
            ></ToggleButton>
            <ControlTitle>{t("admin_page.maintenance_mode")}</ControlTitle>
            <ToggleButton
              inState={maintenance}
              idOff={"maintenanceOff"}
              idOn={"maintenanceOn"}
              callbackOff={toggleMaintenance}
              callbackOn={toggleMaintenance}
            ></ToggleButton>
          </PanelBody>
        </Panel>
        <canvas id="adminCanvas"></canvas>
        <Panel>
          <PanelTitle>{t("admin_page.contributing_users")}</PanelTitle>
          <UserPanelBody>
            {usersList && (
              <UserList
                id="userList"
                userData={usersList}
                callback={userClickWrapper}
              ></UserList>
            )}
          </UserPanelBody>
        </Panel>
      </ControlPanel>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: ${(props) => props.containerHeight}px;
  position: relative;
  background-color: #fafafa;

  #adminCanvas {
    box-shadow: 0px 3px 6px #00000029;
  }

  .highlight {
    background-color: ${netflixRed} !important;
    transition: 100ms;
    color: white;
    padding: 0 0.5rem;
    border-radius: 0.25rem;
  }
`;

const ControlPanel = styled.div`
  display: flex;
  /*justify-content: space-evenly;*/
  margin: 0 auto;
  padding: 2rem 1rem;
  flex-wrap: wrap;
  gap: 2.2rem;
  max-width: 1586px;
`;

const Panel = styled.div`
  background-color: white;
  padding: 2.18rem 2.6rem;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  justify-content: space-around;
  &:first-of-type {
    max-width: 350px;
  }
  :not(&:first-of-type) {
    flex-grow: 1;
  }

  @media ${smallDesktop} {
    padding: 1.5rem 2rem;
    flex-grow: 1;
    &:first-of-type {
      max-width: unset;
    }
  }
`;

const PanelTitle = styled.h2`
  margin: 0 auto;
  font-family: NetflixSansBold;
  font-size: 1.2rem;
  text-transform: uppercase;
`;

const PanelBody = styled.div`
  text-align: left;
  font-size: 0.9rem;
  clear: both;
  li {
    line-height: 1.5rem;
    font-family: NetflixSansLight;
    b {
      font-family: NetflixSansMedium;
      font-weight: normal;
    }
  }
  ul {
    padding: 0;
    list-style: none;
  }
`;

const ControlTitle = styled.div`
  clear: both;
  font-family: NetflixSansRegular;
  font-size: 0.9rem;
  color: #141414;
  margin-top: 1.125rem;
`;

const UserPanelBody = styled(PanelBody)``;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const AddUser = styled.div`
  display: flex;
  align-items: center;
  font-family: NetflixSansMedium;
  margin-top: 0.25rem;
  b {
    font-weight: 400;
  }
`;

const AddUserInner = styled.div`
  flex-grow: 2;
  position: relative;
  display: flex;
`;

const AddUserInput = styled.input`
  margin-left: 0.5rem;
  padding: 0 1.75rem 0 0.25rem;
  border: 1px solid;
  outline: none;
  font-size: 1rem;
  font-family: NetflixSansLight;
  width: 280px;
  border-radius: 0.25rem;
  border-color: #dadada;
  background-color: #fafafa;
  flex-grow: 2;
  box-sizing: border-box;
  line-height: 1.5rem;
`;

const AddUserButton = styled.button`
  position: absolute;
  right: 0.25rem;
  border-radius: 0.25rem;
  background-color: #fafafa;
  top: 0.275rem;
  border: none;
  :hover {
    cursor: pointer;
    background-color: ${colorGrayLight};
  }
`;

export { Admin };

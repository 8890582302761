import { stripPunctuation } from "../utils/strip_punctuation";

const getFieldData = () => {
  const title = document.getElementById("canvasTitle").value;
  const searchTitle = title.toLowerCase();
  let terms = searchTitle.split(" ");
  let searchTerms = [];
  for (let i = 0; i < terms.length; i++) {
    let t = stripPunctuation(terms[i]); //terms[i].replace(/[\W\d]/g, "").replace(/s{2,}/g, "");
    if (t !== "") {
      searchTerms.push(t);
    }
  }
  const description = document.getElementById("canvasDescription").value;
  const type = document.getElementById("canvasTypeSelect").value;
  let userListDiv = document.getElementById("userList");
  let users = Array.from(userListDiv.querySelectorAll("li")).map((val) => {
    return val.innerText;
  });
  let eventTemplate = document.getElementById("eventSelect").value;

  let data = {
    title: title,
    search_title: searchTitle,
    search_terms: searchTerms,
    description: description,
    type: type,
    users: users,
    event: eventTemplate ? JSON.parse(eventTemplate).id : null,
  };
  return data;
};
const clearInputs = () => {
  document.getElementById("eventSelect").value = "";
  document.getElementById("canvasTitle").value = "";
  document.getElementById("canvasDescription").value = "";
  document.getElementById("canvasTypeSelect").value = "";
};

export { getFieldData, clearInputs };
